import ImageSizes from "../assets/image-sizes";

export const getImageDimensions = imageUrl => {
  const urlParts = imageUrl.split("/");
  const fileName = urlParts[urlParts.length - 1];
  const originalFileName = fileName.split(".")[0];
  const dimensions = ImageSizes[originalFileName];

  if (dimensions) {
    return dimensions;
  }

  return null;
};

export const getSizerStylesFromDimensions = dimensions => {
  let imageSizerStyles = {};

  if (dimensions !== null) {
    imageSizerStyles = {
      paddingTop: (dimensions.height / dimensions.width) * 100 + "%",
    };
  }

  return imageSizerStyles;
};
