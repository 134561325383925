import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/prospero-ansty-coventry-warwickshire.jpg";
import Image2 from "../../assets/images/prospero-aerial-1.jpg";
import Image2Large from "../../assets/images/prospero-aerial-1-large.jpg";
import Image3 from "../../assets/images/occupiers-at-ansty-park.png";
import Image4 from "../../assets/images/amtc-futures.jpg";
import Image5 from "../../assets/images/levc-factory-coventry.jpg";
import Image6 from "../../assets/images/prospero-night-shot.jpg";

export const prosperoAnsty = {
  id: 8,
  name: "Prospero Ansty",
  "short-description":
    "The leading Midlands advanced manufacturing technology site offers 2,300,000sqft.<br /><br />" +
    "Situated six miles to the north east of Coventry city centre, Prospero Ansty is at the centre of the UK’s manufacturing heartland. The site is situated at Junction 2 of the M6 and Junction 1 of the M69, enabling instant access to the motorway network and national and international connections.",
  image: {
    url: Image6,
    alt: "Prospero Ansty",
    thumbnail: Image6,
  },
  position: {
    top: 34,
    left: 71,
  },
  ecosystem: [
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://player.vimeo.com/video/686145889" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Home to Rolls Royce Aerospace since 1966, Prospero Ansty is home a number of global technology companies.",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://www.youtube.com/embed/Q9gBfhbm1-g?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "Prospero Ansty",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Geely London Electric Vehicle Company Manufactuirng HQ was the UK's first purpose built EV factory.",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://www.youtube.com/embed/zSc9Kxpwxhc?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image4,
        alt: "AMTC Futures",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The Manufacturing Technology Centre, opened in 2009, is a UK mnufactuirng catapult centre, and works with industry to innovate manufacturing processes. It has 100 corporate members, including Boeing, DMG MORI and ABB.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        bigUrl: Image3,
        alt: "Occupier Logos",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The MTC has expanded to include the Lloyds Bank Advanced Manufactuirng Centre, for training; the HTRC - The High Temperature Research Centre - from Rolls Royce, and the Sopwith Building, an incubator for tech companies.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image5,
        alt: "LEVC Factory Coventry",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Other blue chip tenants include AVL, Fanuc and the new £130m Meggitt HQ and technology centre, opening in 2020.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        bigUrl: Image2Large,
        alt: "Prospero Ansty Aerial View",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The new 90,000sqft Cadent building was the largest out of town office building announced in 2021.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "196 acres (79 hectares)",
    },
    {
      title: "Usage",
      value: "B1, B2, High tech business park environment",
    },
    {
      title: "Developer",
      value: "Manse Opus LLP",
    },
    {
      title: "Authority",
      value: "Rugby Borough Council",
    },
    {
      title: "Local to",
      value:
        "Cadent, Rolls Royce Aero, Geely London EV Company, Fanuc, AVL Powertrain",
    },
  ],
  contact: {
    website: {
      url: "https://www.prospero-ansty.com/",
      title: "Prospero Anstry",
    },
    postcode: "CV7 9JR",
    links: [
      {
        url: "https://youtu.be/Q9gBfhbm1-g",
        title: "The MTC",
      },
      {
        url: "https://youtu.be/zSc9Kxpwxhc",
        title: "VLR",
      },
    ],
  },
};
