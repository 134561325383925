import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/stoneleigh-park-aerial.jpg";
import Image1Cropped from "../../assets/images/stoneleigh-park-aerial-cropped.png";
import Image2 from "../../assets/images/ahdb.jpg";
import Image3 from "../../assets/images/naec-stoneleigh.jpg";
import Image4 from "../../assets/images/rural-innovation-centre.jpg";

export const stoneleighPark = {
  id: 12,
  name: "Stoneleigh Park",
  "short-description":
    "Comprising over 1,000 acres of land close to Coventry and Warwick, Stoneleigh Park is a unique site and the perfect business location for science-based rural industries.<br /><br />" +
    "Stoneleigh sits firmly at the heart of England’s rural economy and is home to a cluster of over 60 businesses with their emphasis on food production, equine and livestock husbandry, sustainability, renewable energy, and the wider rural economy.<br /><br />" +
    "Under a 150 year lease from the Royal Agricultural Society of England, Stoneleigh Park is being transformed into a science park dedicated to rural sciences, innovation and business.<br /><br />" +
    "There is a wide choice of commercial property to let. Also available are bespoke new development and pre-let opportunities that can provide customised buildings to meet occupiers’ requirements.",
  image: {
    url: Image1,
    alt: "Stoneleigh Park",
    thumbnail: Image1Cropped,
  },
  position: {
    top: 52,
    left: 54.5,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Onsite facilities include extensive free car parking, a 58-bedroom hotel, café and farm shop, gym, nursery facilities, eight conference rooms, a 4x4 course and three helicopter landing pads.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "AHDB",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Stoneleigh Park has, amongst the 100 companies on site, some of the most influential associations and organisations representing the rural and land based economy. They include the Royal Agricultural Society of England, the National Farmers Union and the Agriculture and Horticulture Development Board.<br/><br/>" +
        "These bodies can assist in developing agri-knowledge, as well as open doors to UK decision and policy makers.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "NAEC Stoneleigh",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The nearby universities of Warwick and Coventry and Warwickshire College have research departments that specialise in agri-and bio-sciences. They can assist tenants with contract research, consultancy, training and workshops, graduate placements and apprentices, as well as provide access to equipment and expertise.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image4,
        alt: "Rural Innovation Centre",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Tenants onsite include food standards body AHDB, farmer's trade body NFU HQ, NAEC National Agricultural Exhibition Centre, with 21,000sqm of flexible purpose built indoor exhibition space, and 800 acres of outdoor space, with ample parking for over 19,000 cars.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "1,000 acres",
    },
    {
      title: "Usage",
      value: "Science-based rural industries",
    },
    {
      title: "Developer",
      value: "AEW",
    },
    {
      title: "Authority",
      value: "Warwick District Council",
    },
    {
      title: "Local to",
      value: "AHDB, NFU, National Agricultural Exhibition Centre (NAEC)",
    },
  ],
  contact: {
    website: {
      url: "http://www.stoneleighparkestate.com/",
      title: "Stoneleigh Park",
    },
    email: "colin.hooper@eu.jll.com",
    postcode: "CV8 2LG",
  },
};
