import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import MapOutline from "../../assets/graphics/map-outline.svg";
import MapMarker from "./map-marker";
import MapControls from "./map-controls";
import ExtraControls from "./extra-controls";
import MapLocationMarker from "./map-location-marker";
import MapRoadLabel from "./map-road-label";
import MapTransportMarker from "./map-transport-marker";
import "./map.scss";

/**
 * Note: Whilst the mapRef is passed to the element with class "map". It is "map-scrollable"
 * that actually has the dom events. Any controls that act independently of the pan/zoom should
 * therefore we outside of scrollable, to avoid propagation.
 */

class MapPresentational extends PureComponent {
  render() {
    const {
      markers,
      mapRef,
      mapSvgRef,
      onMapClick,
      onMarkerClick,
      onMarkerMouseDown,
      onMarkerMouseUp,
      onImageLoad,
      markerContainerSize,
      markerOffset,
      clickedMarker,
      markersAreVisible,
    } = this.props;

    const overlayClasses = classnames("map__overlay", {
      "map__overlay--visible": markersAreVisible,
    });

    return (
      <div className="map-container" onMouseUp={onMarkerMouseUp}>
        <div className="map-scrollable">
          <div
            className="map"
            ref={mapRef}
            onClick={onMapClick}
            onTouchEnd={onMapClick}
          >
            <div className="map__graphic">
              <img
                src={MapOutline}
                className="map__image"
                onLoad={onImageLoad}
                ref={mapSvgRef}
                alt="map"
              />
            </div>
            <div className={overlayClasses}>
              <div className="map__decoration" style={markerContainerSize}>
                <MapLocationMarker
                  position={{ top: "15%", left: "31%" }}
                  radius={5}
                  scale={markerContainerSize.height}
                  label="Coleshill"
                  labelScaleOffset={markerOffset}
                  labelPosition={{
                    top: "5%",
                    left: "78%",
                  }}
                  labelAnchor="left"
                />
                <MapLocationMarker
                  position={{ top: "12%", left: "61.5%" }}
                  radius={5}
                  scale={markerContainerSize.height}
                  label="Nuneaton"
                  labelScaleOffset={markerOffset}
                  labelPosition={{
                    top: "15%",
                    left: "-11%",
                  }}
                  labelAnchor="right"
                />
                <MapLocationMarker
                  position={{ top: "33%", left: "54%" }}
                  radius={8}
                  scale={markerContainerSize.height}
                  label="Coventry"
                  labelScaleOffset={markerOffset}
                  labelPosition={{
                    top: "73%",
                    left: "-24%",
                  }}
                  labelAnchor="right"
                />
                <MapLocationMarker
                  position={{ top: "40%", left: "86%" }}
                  radius={5}
                  scale={markerContainerSize.height}
                  label="Rugby"
                  labelScaleOffset={markerOffset}
                  labelPosition={{
                    top: "-14%",
                    left: "12%",
                  }}
                  labelAnchor="right"
                />
                <MapLocationMarker
                  position={{ top: "60%", left: "46%" }}
                  radius={5}
                  scale={markerContainerSize.height}
                  label="Warwick"
                  labelScaleOffset={markerOffset}
                  labelPosition={{
                    top: "78%",
                    left: "49%",
                  }}
                  labelAnchor="left"
                />
                <MapLocationMarker
                  position={{ top: "58%", left: "55%" }}
                  radius={5}
                  scale={markerContainerSize.height}
                  label="Royal<br />Leamington Spa"
                  labelScaleOffset={markerOffset}
                  labelPosition={{
                    top: "5%",
                    left: "81%",
                  }}
                  labelAnchor="left"
                />
                <MapLocationMarker
                  position={{ top: "76%", left: "26%" }}
                  radius={5}
                  scale={markerContainerSize.height}
                  label="Stratford-upon-Avon"
                  labelScaleOffset={markerOffset}
                  labelPosition={{
                    top: "105%",
                    left: "-55%",
                  }}
                  labelAnchor="left"
                />
                <MapRoadLabel
                  position={{ top: "4.5%", left: "35.5%" }}
                  scaleOffset={markerOffset}
                  label="M42"
                  type="major"
                />
                <MapRoadLabel
                  position={{ top: "40%", left: "25%" }}
                  scaleOffset={markerOffset}
                  label="M42"
                  type="major"
                />
                <MapRoadLabel
                  position={{ top: "54%", left: "30%" }}
                  scaleOffset={markerOffset}
                  label="M40"
                  type="major"
                />
                <MapRoadLabel
                  position={{ top: "82%", left: "64%" }}
                  scaleOffset={markerOffset}
                  label="M40"
                  type="major"
                />
                <MapRoadLabel
                  position={{ top: "25.5%", left: "46%" }}
                  scaleOffset={markerOffset}
                  label="M6"
                  type="major"
                />
                <MapRoadLabel
                  position={{ top: "33%", left: "81%" }}
                  scaleOffset={markerOffset}
                  label="M6"
                  type="major"
                />
                <MapRoadLabel
                  position={{ top: "51%", left: "51%" }}
                  scaleOffset={markerOffset}
                  label="A46"
                  rotation={-54}
                  type="minor"
                />
                <MapRoadLabel
                  position={{ top: "69%", left: "39%" }}
                  scaleOffset={markerOffset}
                  label="A46"
                  rotation={-34}
                  type="minor"
                />
                <MapRoadLabel
                  position={{ top: "1%", left: "51%" }}
                  scaleOffset={markerOffset}
                  label="A5"
                  rotation={30}
                  type="minor"
                />
                <MapRoadLabel
                  position={{ top: "16.5%", left: "81%" }}
                  scaleOffset={markerOffset}
                  label="A5"
                  rotation={30}
                  type="minor"
                />
                <MapTransportMarker
                  position={{ top: "46%", left: "62%" }}
                  scaleOffset={markerOffset}
                  scale={markerContainerSize.height}
                  label="Coventry<br />Airport"
                  labelPosition={{ top: "-11%", left: "15%" }}
                />
                <MapTransportMarker
                  position={{ top: "76%", left: "44%" }}
                  scaleOffset={markerOffset}
                  scale={markerContainerSize.height}
                  label="Wellesbourne<br />Airport"
                  labelPosition={{ top: "-90%", left: "-106%" }}
                />
                <MapTransportMarker
                  position={{ top: "26%", left: "26%" }}
                  scaleOffset={markerOffset}
                  scale={markerContainerSize.height}
                  label="Birmingham International<br />Airport and HS2 Hub"
                  labelPosition={{ top: "5%", left: "-71%" }}
                />
              </div>
            </div>
            <div className={overlayClasses}>
              <div className="map__markers" style={markerContainerSize}>
                {markers.map(marker => {
                  const {
                    position: { top, left },
                    id,
                  } = marker;
                  return (
                    <MapMarker
                      key={"marker-" + id}
                      label={id}
                      position={{ top: top + "%", left: left + "%" }}
                      onClick={() => onMarkerClick(id)}
                      onMouseUp={onMarkerMouseUp}
                      onMouseDown={onMarkerMouseDown}
                      scaleOffset={markerOffset}
                      isActive={clickedMarker === id}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="controls-container controls-container--extra">
          <ExtraControls />
        </div>
        <div className="controls-container controls-container--map">
          <MapControls />
        </div>
      </div>
    );
  }
}

MapPresentational.propTypes = {
  markers: PropTypes.array,
  mapSvgRef: PropTypes.any,
  mapRef: PropTypes.any,
  markerContainerSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  onMapClick: PropTypes.func,
  onMarkerClick: PropTypes.func,
  onMarkerMouseDown: PropTypes.func,
  onMarkerMouseUp: PropTypes.func,
  onImageLoad: PropTypes.func,
  markerOffset: PropTypes.number,
  activeMarker: PropTypes.number,
  markersAreVisible: PropTypes.bool,
  clickedMarker: PropTypes.number,
};

MapPresentational.defaultProps = {
  markerOffset: 1,
  markersAreVisible: false,
};

export default MapPresentational;
