import {
  CLEAR_CLICKED_MARKER,
  CLOSE_PREVIEW,
  SET_ACTIVE_PREVIEW,
  SET_CLICKED_MARKER,
  SET_ZOOM_LEVEL,
  TOGGLE_CONTACT_DETAILS,
} from "./constants";
import {
  CLOSE_CONTENT_PANEL,
  SET_ACTIVE_CONTENT_ID,
} from "../content-status/constants";

const defaultMapStatus = {
  activeHotspot: null,
  clickedHotspot: null,
  mapConfig: {
    zoomLevel: 1,
  },
  previewPanel: {
    isVisible: false,
  },
  contactDetails: {
    isVisible: false,
  },
};

export const mapStatus = (state = defaultMapStatus, action) => {
  switch (action.type) {
    case SET_ACTIVE_PREVIEW:
      return {
        ...state,
        activeHotspot: action.hotspotId,
        previewPanel: {
          ...state.previewPanel,
          isVisible: true,
        },
      };
    case CLOSE_PREVIEW:
      return {
        ...state,
        activeHotspot: null,
        previewPanel: {
          ...state.previewPanel,
          isVisible: false,
        },
      };
    case SET_ACTIVE_CONTENT_ID:
      return {
        ...state,
        activeHotspot: action.contentId,
        previewPanel: {
          ...state.previewPanel,
          isVisible: false,
        },
      };
    case CLOSE_CONTENT_PANEL:
      return {
        ...state,
        activeHotspot: null,
      };
    case SET_ZOOM_LEVEL:
      return {
        ...state,
        mapConfig: {
          ...state.mapConfig,
          zoomLevel: action.level,
        },
      };
    case SET_CLICKED_MARKER:
      return {
        ...state,
        clickedHotspot: action.hotspotId,
      };
    case CLEAR_CLICKED_MARKER:
      return {
        ...state,
        clickedHotspot: null,
      };
    case TOGGLE_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          isVisible: !state.contactDetails.isVisible,
        },
      };
    default:
      return state;
  }
};
