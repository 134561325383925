import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/collectionc-centre-1.jpg";
import Image2 from "../../assets/images/collectionc-centre-2.jpg";
import Image1Cropped from "../../assets/images/collectionc-centre-cropped.jpg";

export const collectionsCentre = {
  id: 7,
  name: "Collections Centre/Sky Blue Studios",
  "short-description":
    "The former IKEA building in the City Centre is in the design and planning phases of becoming a Collections Centre of national importance, working in partnership with national organisations to house some of the nation’s finest art. As another legacy of the UK City of Culture year, this project will also present extensive business investment opportunities.",
  image: {
    url: Image1,
    alt: "Collections Centre",
    bigUrl: Image1,
    thumbnail: Image1Cropped,
  },
  position: {
    top: 39.7,
    left: 61.5,
  },
  ecosystem: [
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://player.vimeo.com/video/686143188" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Sky Blue Studios is an innovative and sustainability-first media and studio facility in Coventry, West Midlands focused on the film, TV and immersive media production sector. Repurposing the former IKEA superstore, this unique building offers 550,000 sq ft across 6 floors of studio, location and production space with onsite parking and excellent eco-credentials.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Sky Blue Studios",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The concept is simple – a central home for sustainable creativity during Coventry’s most important year, a legacy that will position the city at the centre of creativity, innovation and inward investment in one of the fastest growing sectors in the UK, creative content production.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "550.000sqft (over 6 floors)",
    },
    {
      title: "Usage",
      value: "Mixed use including exhibition and film studio space",
    },
    {
      title: "Developer",
      value: "Coventry City Council",
    },
    {
      title: "Authority",
      value: "Coventry County Council",
    },
    {
      title: "Local to",
      value: "Coventry Station, Coventry University, Fargo",
    },
  ],
  contact: {
    website: {
      url: "https://skybluestudios.uk/about-sky-blue-studios/",
      title: "Sky Blue Studios",
    },
    postcode: "B76 9EA",
    links: [
      {
        url: "https://youtu.be/zSc9Kxpwxhc",
        title: "VLR",
      },
    ],
  },
};
