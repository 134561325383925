import { CLOSE_OVERLAY, SET_IMAGE_IN_OVERLAY } from "./constant";

const imageOverlayDefault = {
  image: {
    url: null,
    alt: null,
  },
  isVisible: false,
};

export const imageOverlay = (state = imageOverlayDefault, action) => {
  switch (action.type) {
    case SET_IMAGE_IN_OVERLAY:
      return {
        ...state,
        image: action.image,
        isVisible: true,
      };
    case CLOSE_OVERLAY:
      return {
        ...imageOverlayDefault,
      };
    default:
      return state;
  }
};
