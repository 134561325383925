import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./map-location-marker.scss";

class MapLocationMarkerPresentational extends PureComponent {
  render() {
    const {
      position,
      radius,
      scale,
      label,
      labelPosition,
      labelScaleOffset,
      labelAnchor,
    } = this.props;
    const size = (radius / 100) * scale;
    const circleStyle = {
      ...position,
      width: size,
      height: size,
    };
    let labelOrigin = "center center";
    let labelTransform = "scale(" + labelScaleOffset + ")";

    if (labelAnchor === "left") {
      labelOrigin = "center left";
    } else if (labelAnchor === "right") {
      labelOrigin = "center right";
      labelTransform = "translateX(-100%) scale(" + labelScaleOffset + ")";
    }

    const labelStyle = {
      ...labelPosition,
      transform: labelTransform,
      transformOrigin: labelOrigin,
      textAlign: labelAnchor,
    };

    return (
      <div className="map-location-marker" style={circleStyle}>
        <div
          className="map-location-marker__label"
          style={labelStyle}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>
    );
  }
}

MapLocationMarkerPresentational.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
  }).isRequired,
  radius: PropTypes.number,
  scale: PropTypes.number,
  label: PropTypes.string,
  labelPosition: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
  }),
  labelScaleOffset: PropTypes.number,
  labelAnchor: PropTypes.oneOf(["left", "right", "center"]),
};

MapLocationMarkerPresentational.defaultProps = {
  radius: 5,
  labelAnchor: "center",
};

export default MapLocationMarkerPresentational;
