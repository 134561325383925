import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import VideoEmbedContentBlockPresentational from "./presentational";

class VideoEmbedContentBlockFunctional extends PureComponent {
  constructor(props) {
    super(props);

    this.iframeContainer = React.createRef();
  }

  componentDidMount() {
    this._setupIframeEventListener();
  }

  _setupIframeEventListener() {
    // This is a horrible fix to a problem we saw on Safari IOS.
    // When exiting full screen mode, the height of the window would not be set correctly.
    // Waiting a short time and forcing recalculation in JS and CSS layers fixes this.
    window.addEventListener("resize", e => {
      const iframe = this.iframeContainer.current.getElementsByTagName(
        "iframe",
      )[0];
      if (iframe && iframe.clientWidth === window.innerWidth) {
        // Exiting full screen
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
          document.getElementById("root").style.display = "none";
          setTimeout(() => {
            document.getElementById("root").style.display = "block";
          }, 50);
        }, 50);
      }
    });
  }

  render() {
    const {
      contentPanelIsVisible,
      iframeEmbedCode,
      aspectRatio,
      caption,
      tabKey,
    } = this.props;

    if (!contentPanelIsVisible) {
      return null;
    }

    return (
      <VideoEmbedContentBlockPresentational
        key={"video-on-tab" + tabKey}
        iframeEmbedCode={iframeEmbedCode}
        aspectRatio={aspectRatio}
        caption={caption}
        iframeContainerRef={this.iframeContainer}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    contentPanelIsVisible: state.contentStatus.isVisible,
    tabKey: state.contentStatus.activeTabKey,
  };
};

VideoEmbedContentBlockFunctional.propTypes = {
  iframeEmbedCode: PropTypes.string,
  aspectRatio: PropTypes.oneOf(["4:3", "16:9"]),
  caption: PropTypes.string,
};

VideoEmbedContentBlockFunctional.defaultProps = {
  aspectRatio: "16:9",
};

export default connect(mapStateToProps)(VideoEmbedContentBlockFunctional);
