import { Component } from "react";
import PropTypes from "prop-types";

class TabItemPresentational extends Component {
  render() {
    // This element will be transformed by the parent tab component.
    return null;
  }
}

TabItemPresentational.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(["world", "chart", "link"]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default TabItemPresentational;
