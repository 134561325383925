import React, { PureComponent } from "react";
import WelcomeMessagePresentational from "./presentational";

class WelcomeMessageFunctional extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true,
    };

    this._removeWelcomeMessage = this._removeWelcomeMessage.bind(this);
  }

  _removeWelcomeMessage() {
    this.setState({
      isActive: false,
    });
  }

  render() {
    const { isActive } = this.state;

    if (!isActive) {
      return null;
    }

    return (
      <WelcomeMessagePresentational afterClosed={this._removeWelcomeMessage} />
    );
  }
}

export default WelcomeMessageFunctional;
