import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { setZoomLevel } from "../../../../actions/map-status/actions";
import ZoomControlsPresentational from "./presentational";
import {
  MAP_ZOOM_INCREMENT,
  MAX_MAP_ZOOM,
  MIN_MAP_ZOOM,
} from "../../../../constants/map-settings";

class ZoomControlsFunctional extends PureComponent {
  constructor(props) {
    super(props);

    this._zoomIn = this._zoomIn.bind(this);
    this._zoomOut = this._zoomOut.bind(this);
  }

  _zoomIn() {
    const { zoomLevel, setZoomLevel } = this.props;
    let newZoomLevel = zoomLevel + MAP_ZOOM_INCREMENT;
    newZoomLevel = newZoomLevel > MAX_MAP_ZOOM ? MAX_MAP_ZOOM : newZoomLevel;

    setZoomLevel(newZoomLevel);
  }

  _zoomOut() {
    const { zoomLevel, setZoomLevel } = this.props;
    let newZoomLevel = zoomLevel - MAP_ZOOM_INCREMENT;
    newZoomLevel = newZoomLevel < MIN_MAP_ZOOM ? MIN_MAP_ZOOM : newZoomLevel;

    setZoomLevel(newZoomLevel);
  }

  render() {
    const { zoomLevel } = this.props;
    return (
      <ZoomControlsPresentational
        onZoomIn={this._zoomIn}
        onZoomOut={this._zoomOut}
        zoomInEnabled={zoomLevel < MAX_MAP_ZOOM}
        zoomOutEnabled={zoomLevel > MIN_MAP_ZOOM}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    zoomLevel: state.mapStatus.mapConfig.zoomLevel,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setZoomLevel: level => {
      dispatch(setZoomLevel(level));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ZoomControlsFunctional);
