import React, { PureComponent } from "react";
import ZoomControls from "./zoom-controls";

class MapControlsPresentational extends PureComponent {
  render() {
    return (
      <div className="map-controls">
        <ZoomControls />
      </div>
    );
  }
}

export default MapControlsPresentational;
