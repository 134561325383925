import React, { Component } from "react";
import PropTypes from "prop-types";
import MessageOverlayPresentational from "./presentational";

class MessageOverlayFunctional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExiting: !props.isOpen,
    };

    this.activeTimer = null;

    this.transitionOutTime = 800;
    this.fadeOutTime = 500;

    this._closeMessageOverlay = this._closeMessageOverlay.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isExiting } = this.state;

    if (isOpen && !prevProps.isOpen && isExiting) {
      this.setState({
        isExiting: false,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.activeTimer);
  }

  _closeMessageOverlay() {
    const { afterClosed } = this.props;
    const totalAnimationTime =
      (3 * this.transitionOutTime) / 4 + this.fadeOutTime;

    this.setState({
      isExiting: true,
    });

    this.activeTimer = setTimeout(() => {
      afterClosed();
    }, totalAnimationTime);
  }

  render() {
    const { children, buttonText } = this.props;
    const { isExiting } = this.state;

    return (
      <MessageOverlayPresentational
        children={children}
        buttonText={buttonText}
        onClose={this._closeMessageOverlay}
        animationOutDuration={this.transitionOutTime}
        fadeOutDuration={this.fadeOutTime}
        isExiting={isExiting}
      />
    );
  }
}

MessageOverlayFunctional.propTypes = {
  children: PropTypes.any.isRequired,
  buttonText: PropTypes.string.isRequired,
  afterClosed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

MessageOverlayFunctional.defaultProps = {
  buttonText: "Close",
  isOpen: false,
};

export default MessageOverlayFunctional;
