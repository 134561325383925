import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "../content-blocks.scss";

class ParagraphContentBlockPresentational extends PureComponent {
  render() {
    const { textWithHtml } = this.props;

    if (!textWithHtml) {
      return null;
    }

    return (
      <p
        className="content-block content-block--paragraph"
        dangerouslySetInnerHTML={{ __html: textWithHtml }}
      />
    );
  }
}

ParagraphContentBlockPresentational.propTypes = {
  textWithHtml: PropTypes.string,
};

export default ParagraphContentBlockPresentational;
