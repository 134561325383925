import { CLOSE_OVERLAY, SET_IMAGE_IN_OVERLAY } from "./constant";

export const setImageInOverlay = image => {
  return {
    type: SET_IMAGE_IN_OVERLAY,
    image,
  };
};

export const closeOverlay = () => {
  return {
    type: CLOSE_OVERLAY,
  };
};
