import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CloseButton from "../close-button";
import "./image-overlay.scss";

class ImageOverlayPresentational extends PureComponent {
  render() {
    const { url, alt, imageRef, onClose } = this.props;

    return (
      <div className="image-overlay">
        <div className="image-overlay__close">
          <CloseButton onClose={onClose} light />
        </div>
        <div className="image-overlay__background" onClick={onClose} />
        <div className="image-overlay__image-container">
          <img
            className="image-overlay__image"
            ref={imageRef}
            src={url}
            alt={alt}
          />
        </div>
      </div>
    );
  }
}

ImageOverlayPresentational.propTypes = {
  imageRef: PropTypes.any,
  url: PropTypes.string,
  alt: PureComponent.string,
  onClose: PropTypes.func.isRequired,
};

export default ImageOverlayPresentational;
