import React, { Component } from "react";
import { connect } from "react-redux";
import { closeOverlay } from "../../actions/image-overlay/actions";
import ImageOverlayPresentational from "./presentational";
import * as panzoom from "panzoom";

class ImageOverlayFunctional extends Component {
  constructor(props) {
    super(props);

    this.image = null;
    this.imageRef = React.createRef();

    this._initialisePanZoom = this._initialisePanZoom.bind(this);
  }

  componentDidMount() {
    const { isVisible } = this.props;

    if (isVisible) {
      this._initialisePanZoom();
    }
  }

  componentDidUpdate() {
    const { isVisible } = this.props;
    if (isVisible && this.image === null) {
      this._initialisePanZoom();
    } else if (!isVisible && this.image !== null) {
      this.image.dispose();
      this.image = null;
    }
  }

  _initialisePanZoom() {
    this.image = panzoom(this.imageRef.current, {
      bounds: true,
      boundsPadding: 1,
      maxZoom: 3,
      minZoom: 1,
    });
  }

  render() {
    const { image, isVisible, closeOverlay } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <ImageOverlayPresentational
        onClose={closeOverlay}
        imageRef={this.imageRef}
        url={image.url}
        alt={image.alt}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    image: state.imageOverlay.image,
    isVisible: state.imageOverlay.isVisible,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeOverlay: () => {
      dispatch(closeOverlay());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageOverlayFunctional);
