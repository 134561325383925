import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ReactComponent as AirportIcon } from "../../../assets/graphics/airport.svg";
import "./map-transport-marker.scss";

class MapTransportMarkerPresentational extends PureComponent {
  render() {
    const {
      position,
      label,
      labelPosition,
      labelAnchor,
      scale,
      scaleOffset,
    } = this.props;
    const size = (3 / 100) * scale;
    const markerStyles = {
      ...position,
      width: size,
      height: size,
      transform: "scale(" + scaleOffset + ") translate(-50%, -50%)",
    };

    const labelStyles = {
      transform:
        "translate(" + labelPosition.left + ", " + labelPosition.top + ")",
      textAlign: labelAnchor === "right" ? "right" : "left",
    };
    return (
      <div className="map-transport-marker" style={markerStyles}>
        <AirportIcon />
        <div
          className="map-transport-marker__label"
          style={labelStyles}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>
    );
  }
}

MapTransportMarkerPresentational.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
  }),
  scaleOffset: PropTypes.number,
  scale: PropTypes.number,
};

MapTransportMarkerPresentational.defaultProps = {
  scale: 1,
};

export default MapTransportMarkerPresentational;
