import Image1 from "../../assets/images/transforming-nuneaton-1.png";
import Image2 from "../../assets/images/new-library-transforming-nuneaton.png";
import Image3 from "../../assets/images/ropewalk-shopping-centre-transforming-nuneaton.jpg";
import Image4 from "../../assets/images/hello_fresh_beehive_nuneaton.jpg";
import Image5 from "../../assets/images/transforming-nuneaton.jpg";

import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";

export const transformNuneaton = {
  id: 3,
  name: "Transforming Nuneaton",
  "short-description":
    "This regeneration Masterplan is aimed at creating a vibrant town centre via an ambitious programme of mixed-use development, including residential, leisure and offices. Abbey Street is the first phase of the plan and is under construction with public investment already committed to deliver 12,000m2 of new buildings. Anchored by a new library and business centre, it is also it is also the mainline station town for the 200,000m2 auto technology campus MIRA Technology Park and is on the doorstep of two major research universities.",
  image: {
    url: Image5,
    alt: "Transforming Nuneaton",
    thumbnail: Image5,
  },
  position: {
    top: 15,
    left: 64,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Major town centre transformation opportunity; anchored by new library and business centre",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Nuneaton is the largest town in Warwickshire",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "Nuneaton is the largest town in Warwickshire",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Located near Birmingham and East Midlands airports, and High Speed 2 Hub at UK Central - the first stopping point on HS2 from London",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "Located near Birmingha",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Town centre employers include FedEx, and HQ for Holland & Barrett",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "Ropewalk Shopping Centre",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Nuneaton is the mainline station town for the 200,000m2 auto technology campus MIRA Technology Park and is on the doorstep of two major research universities",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image4,
        alt: "Vicarage Road",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "New 1m sqft Baytree Faultands located next to recent investment from Hello Fresh 240,000sqft Beehive",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "14 Acres (5.6 Hectares)",
    },
    {
      title: "Usage",
      value: "Mixed Use, Residential, Leisure & Office",
    },
    {
      title: "Developer",
      value: "Local Authorities & Queensberry",
    },
    {
      title: "Authority",
      value: "Nuneaton & Bedworth Borough Council",
    },
    {
      title: "Local to",
      value: "Holland & Barrett, Hello Fresh, HORIBA-MIRA, Saputo",
    },
  ],
  contact: {
    website: {
      url: "https://www.warwickshire.gov.uk/transformingnuneaton",
      title: "Warwickshire County Council",
    },
    postcode: "CV11 4DR",
  },
};
