import React, { Component } from "react";
import { connect } from "react-redux";
import ContactDetailsMessagePresentational from "./presentational";
import { toggleContactDetailsVisibility } from "../../actions/map-status/actions";

class ContactDetailsMessageFunctional extends Component {
  render() {
    const { isOpen, closeContactDetails } = this.props;
    return (
      <ContactDetailsMessagePresentational
        afterClosed={closeContactDetails}
        isOpen={isOpen}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.mapStatus.contactDetails.isVisible,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeContactDetails: () => {
      dispatch(toggleContactDetailsVisibility());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactDetailsMessageFunctional);
