import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "../content-blocks.scss";
import { ReactComponent as ExpandIcon } from "../../../../assets/graphics/expand-icon.svg";
import Loader from "../../../loader";
import { getSizerStylesFromDimensions } from "../../../../helpers/images";

class ImageContentBlockPresentational extends PureComponent {
  render() {
    const {
      url,
      alt,
      dimensions,
      caption,
      onZoom,
      onLoad,
      showLoader,
    } = this.props;
    const haveDimensions = dimensions !== null;
    const imageSizerStyles = getSizerStylesFromDimensions(dimensions);

    const imageContainerClasses = classnames("content-block__image-container", {
      "content-block__image-container--sized": haveDimensions,
    });
    const loaderClasses = classnames("content-block__loader", {
      "content-block__loader--loading": showLoader,
    });

    if (!url) {
      return null;
    }

    return (
      <div
        className="content-block content-block--image content-block--clickable"
        onClick={onZoom}
      >
        <img
          className="content-block__image"
          onLoad={onLoad}
          src={url}
          alt={alt}
        />
        <div className={imageContainerClasses}>
          {haveDimensions && (
            <div
              className="content-block__image-sizer"
              style={imageSizerStyles}
            />
          )}
          <div className={loaderClasses}>
            <Loader />
          </div>
        </div>
        {caption && <div className="content-block__caption">{caption}</div>}
        <div className="content-block__expand">
          <ExpandIcon />
        </div>
      </div>
    );
  }
}

ImageContentBlockPresentational.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
  onZoom: PropTypes.func,
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  showLoader: PropTypes.bool,
  onLoad: PropTypes.func,
};

ImageContentBlockPresentational.defaultProps = {
  dimensions: null,
  showLoader: false,
};

export default ImageContentBlockPresentational;
