import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ReactComponent as MarkerIcon } from "../../assets/graphics/map-marker.svg";
import "./marker-with-title.scss";

class MarkerWithTitlePresentational extends PureComponent {
  render() {
    const { markerNumber, children } = this.props;

    return (
      <div className="header-with-marker">
        <div className="header-with-marker__marker">
          <MarkerIcon />
          <span className="header-with-marker__number">{markerNumber}</span>
        </div>
        <h4>{children}</h4>
      </div>
    );
  }
}

MarkerWithTitlePresentational.propTypes = {
  markerNumber: PropTypes.number,
  children: PropTypes.string,
};

export default MarkerWithTitlePresentational;
