import React, { PureComponent } from "react";
import "./header.scss";
import { ReactComponent as Logo } from "../../assets/graphics/logo.svg";

class HeaderPresentational extends PureComponent {
  render() {
    return (
      <header className="header">
        <div className="container header__container">
          <a href="./" className="header__logo">
            <Logo />
          </a>
          <div className="header__tagline">Coventry &amp; Warwickshire</div>
        </div>
      </header>
    );
  }
}

export default HeaderPresentational;
