import {
  PARAGRAPH_CONTENT_TYPE,
  IMAGE_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/daimler-wharf-1.jpg";
import Image2 from "../../assets/images/daimler-wharf-2.jpg";
import Image2Cropped from "../../assets/images/daimler-wharf-2-cropped.png";

export const daimlerWharf = {
  id: 6,
  name: "Daimler Wharf",
  "short-description":
    "A unique opportunity to reinvigorate a community by providing a new central hub, connecting the city's canal with the city centre, and celebrating Coventry’s industrial past and cultural future. This former industrial site will become a model for sustainable living in an adaptable and achievable framework, enhancing the authenticity of its historic location and heritage.",
  image: {
    url: Image2,
    alt: "Daimler Wharf",
    thumbnail: Image2Cropped,
  },
  position: {
    top: 35.5,
    left: 55.5,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "A significant arrival point into Coventry city centre from the north, establishing a new residential and commercial focal point.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "Daimler Wharf",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "A linear park at the centre of the masterplan will connect the neighbourhood to the canal side while delivering common green and blue space for visual amenity and shared activities.",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://player.vimeo.com/video/686143188" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The Powerhouse Arts Centre on the Coventry and Leeds Canal will be enhanced to create a meaningful focal point for cultural businesses and an events location which will become a vital component of the City’s cultural scene.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Pedestrian and cycle priority to provide links to the canal and through to the city centre.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "7 acres (2.8 hectares)",
    },
    {
      title: "Usage",
      value:
        "Mixed use scheme providing residential and commercial spaces with leisure amenities",
    },
    {
      title: "Developer",
      value: "The Wigley Group",
    },
    {
      title: "Authority",
      value: "Coventry City Council",
    },
    {
      title: "Local to",
      value: "Electric Wharf, Imagineer Productions, Coventry City Centre",
    },
  ],
  contact: {
    website: {
      url: "https://www.daimlerwharf.co.uk/",
      title: "Daimler Wharf",
    },
    postcode: "CV1 4DQ",
    links: [
      {
        url: "https://skybluestudios.uk/about-sky-blue-studios/",
        title: "Sky Blue Studios",
      },
      {
        url: "https://www.fargovillage.co.uk/",
        title: "Fargo Village",
      },
      {
        url: "https://imagineer-productions.co.uk/the-daimler-powerhouse/",
        title: "The Daimler Powerhouse",
      },
    ],
  },
};
