import React, { Component } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import ReactGA from "react-ga";
import Header from "./components/header";
import Map from "./components/map";
import PreviewPanel from "./components/preview-panel";
import ContentPanel from "./components/content-panel";
import RotateDevice from "./components/rotate-device";
import WelcomeMessage from "./components/welcome-message";
import ContactDetailsMessage from "./components/contact-details-message";
import ImageOverlay from "./components/image-overlay";
import "./styles/global.scss";

class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
        debug: process.env.REACT_APP_ANALYTICS_DEBUG === "true",
      });
      ReactGA.set({ anonymizeIp: true });
    }
  }

  render() {
    return (
      <Provider store={store}>
        <div className="app-container">
          <Header />
          <Map />
          <PreviewPanel />
          <ContentPanel />
          <RotateDevice />
          <WelcomeMessage />
          <ContactDetailsMessage />
          <ImageOverlay />
        </div>
      </Provider>
    );
  }
}

export default App;
