import {
  CLOSE_CONTENT_PANEL,
  SET_ACTIVE_CONTENT_ID,
  SET_ACTIVE_TAB_KEY,
} from "./constants";

export const setActiveContentId = contentId => {
  return {
    type: SET_ACTIVE_CONTENT_ID,
    contentId,
  };
};

export const closeContentPanel = () => {
  return {
    type: CLOSE_CONTENT_PANEL,
  };
};

export const setActiveTabKey = key => {
  return {
    type: SET_ACTIVE_TAB_KEY,
    key,
  };
};
