import Image1 from "../../assets/images/friargate-3.jpg";
import Image1Cropped from "../../assets/images/friargate-3-cropped.png";
import Image2 from "../../assets/images/friargate-4.jpg";
import Image3 from "../../assets/images/friargate-1.jpg";
import Image3Large from "../../assets/images/friargate-1-large.jpg";
import Image4 from "../../assets/images/friargate-2.jpg";

import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";

export const friargateCoventry = {
  id: 5,
  name: "Friargate Coventry",
  "short-description":
    "A world-class commercial and residential offer in an unbeatable location. It is a mixed-use, multi-phase masterplan that presents many opportunities for differing forms of investment.",
  image: {
    url: Image1,
    alt: "Friargate Coventry",
    thumbnail: Image1Cropped,
  },
  position: {
    top: 39,
    left: 57.5,
  },
  ecosystem: [
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://www.youtube.com/embed/zSc9Kxpwxhc?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Newly established destination is based around commercial developments and public realm. The venue is a commitment to sustainability and class leading environmental standard",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "Friargate Coventry Map",
        bigUrl: Image3Large,
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Located immediately in front of Coventry railway station and within the core of the city centre.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Friargate Coventry",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "London is just an hour away by rail and the heart of Birmingham accessible within twenty-minutes.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image4,
        alt: "Friargate Coventry",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Selected as UK City of Culture in 2021 and taking part in the 2022 Commonwealth Games, Coventry is a city very much on the rise. Indeed, a recent study by national daily newspaper The Guardian identified Coventry as ‘…one of the five stand-out UK cities for jobs and business.’",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The second office, Two Friargate is due for completion in late 2022, while a separate project team will be delivering a new 100-room boutique hotel for completion in 2023.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "3,200,000 sq ft",
    },
    {
      title: "Usage",
      value:
        "Coventry City Council, The Financial Ombudsman Service, Homes England and fast growing local IT business, Kagool.",
    },
    {
      title: "Developer",
      value: "Friargate Coventry Developments Limited",
    },
    {
      title: "Authority",
      value: "Coventry City Council",
    },
    {
      title: "Local to",
      value:
        "City centre Jaguar Land Rover, Rolls Royce Aerospace,, University of Warwick, Coventy Unitersity",
    },
  ],
  contact: {
    website: {
      url: "https://friargatecoventry.co.uk/",
      title: "Friargate Coventry",
    },
    postcode: "CV1 2FJ",
    links: [
      {
        url: "https://skybluestudios.uk/about-sky-blue-studios/",
        title: "Sky Blue Studios",
      },
    ],
  },
};
