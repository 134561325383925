import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ReactComponent as WorldIcon } from "../../assets/graphics/globe.svg";
import { ReactComponent as ChartIcon } from "../../assets/graphics/statistics.svg";
import { ReactComponent as LinkIcon } from "../../assets/graphics/links.svg";
import "./tabs.scss";

class TabsPresentational extends Component {
  render() {
    const {
      tabTitles,
      tabChildren,
      onTitleClick,
      activeIndex,
      tabContentPanelHeight,
      refs,
      tabsRef,
    } = this.props;
    const contentPanelsStyles = {
      height: tabContentPanelHeight,
    };

    return (
      <div className="tabs" ref={tabsRef}>
        <div className="tabs__titles">
          {tabTitles.map((titleObj, index) => {
            const { title, icon } = titleObj;
            const titleClasses = classnames("tabs__title", {
              "tabs__title--active": index === activeIndex,
            });
            return (
              <button
                key={"tab-title-" + index}
                className={titleClasses}
                onClick={() => onTitleClick(index)}
                aria-label={title}
              >
                {(() => {
                  switch (icon) {
                    case "world":
                      return <WorldIcon />;
                    case "chart":
                      return <ChartIcon />;
                    case "link":
                      return <LinkIcon />;
                    default:
                      return null;
                  }
                })()}
              </button>
            );
          })}
        </div>
        <div className="tabs__content-panels" style={contentPanelsStyles}>
          {tabChildren.map((content, index) => {
            const contentPanelClasses = classnames("tabs__content-panel", {
              "tabs__content-panel--active": index === activeIndex,
            });
            return (
              <div
                ref={refs[index]}
                key={"content-panel-" + index}
                className={contentPanelClasses}
              >
                {content}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

TabsPresentational.propTypes = {
  tabTitles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.oneOf(["world", "chart", "link"]),
    }),
  ),
  tabChildren: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  ),
  activeIndex: PropTypes.number,
  onTitleClick: PropTypes.func.isRequired,
  tabContentPanelHeight: PropTypes.number,
  refs: PropTypes.array,
  tabsRef: PropTypes.any,
};

TabsPresentational.defaultProps = {
  activeIndex: 0,
  tabContentPanelHeight: 0,
};

export default TabsPresentational;
