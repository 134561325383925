import Image1 from "../../assets/images/abbey-park.png";
import Image2 from "../../assets/images/abbey-park-2.jpg";
import Image3 from "../../assets/images/abbey-park-3.jpg";
import Image3Cropped from "../../assets/images/abbey-park-3-cropped.png";
import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";

export const abbeyPark = {
  id: 11,
  name: "Abbey Park",
  "short-description":
    "Premium, low density business park to provide 150,000 sqft gross internal area of “Grade A” specification office space spread over five new buildings in this beautiful and unique 60-acre Grade 2 listed parkland setting. Within a 10-minute drive of Coventry, Warwick, Leamington Spa and Kenilworth.",
  image: {
    url: Image3,
    alt: "Abbey Park",
    thumbnail: Image3Cropped,
  },
  position: {
    top: 50,
    left: 57,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Premium low-density development with room to breathe, at a great strategic location, close to the A46.",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe title="vimeo-player" src="https://player.vimeo.com/video/316059928" width="640" height="360" frameborder="0" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "In the heart of the UK's premier automotive and aerospace industries region, the site was once home to Massey Ferguson's tractor development.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "Abbey Park Logo",
        bigUrl: Image1,
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Less than 3 miles from Jaguar Land Rover Global Headquarters, and close to University of Warwick and" +
        " Coventry University.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Abbey Park",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "On site management suite + concierge service, outdoor gym, woodland picnic areas, number plate recognition and fibre broadband.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Existing occupants include the European HQ for AGCO, owners of the Massey Ferguson and Fendt brands, UK HQ for German tier 1 auto supplier Dau Draexlmaier, computer software supplier Aareon, and the British Horse Society.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: " 150,000 sqft (13,935.5 sqm)",
    },
    {
      title: "Usage",
      value: "B1 Office",
    },
    {
      title: "Developer",
      value: "DL Property & Development Strategies Ltd",
    },
    {
      title: "Authority",
      value: "Warwick District Council",
    },
    {
      title: "Local to",
      value: "AGCO, Dau Draxelmaier",
    },
  ],
  contact: {
    website: {
      url: "http://www.abbeyparkoffices.com/",
      title: "Abbey Park Offices",
    },
    postcode: "CV8 2TQ",
  },
};
