import Image1 from "../../assets/images/wellesbourne-innovation-campus.jpg";
import Image2 from "../../assets/images/xt2a0019-wellesebourne.jpg";
import Image3 from "../../assets/images/wellesbourne-main-structure-goes-up.jpg";
import Image4 from "../../assets/images/wellesbourne-exterior.jpg";
import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";

export const wellesbourneInnovation = {
  id: 14,
  name: "University of Warwick, Wellesbourne Innovation Campus",
  "short-description":
    "Wellesbourne Campus is a 192-hectare site located just 14 miles south of the University\n" +
    "of Warwick’s campus in Coventry.\n" +
    "There is now a significant opportunity to develop an initial 30 hectares of the site into a worldclass\n" +
    "innovation and technology campus. The University believes that with the right partners,\n" +
    "its academic expertise in defined sectors, and public and private sector businesses, the campus\n" +
    "will become a beacon for the region and economy.",
  image: {
    url: Image4,
    alt: "University of Warwick, Wellesbourne Innovation Campus",
    thumbnail: Image4,
  },
  position: {
    top: 77,
    left: 47,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Renowned for its leading research and innovation, the University of Warwick has a proven track record\n" +
        "of collaboration with industry. Its researchers work with companies of various sizes – from small businesses to global firms such as Tata Motors, Unilever, Corteva Agriscience and GE Healthcare.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "XT2A0019",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Key sectors for the University of Warwick and WMG are Automotive Tech, including Autonomous and EV technologies; Crop Sciences, Agri-tech and Life Sciences; and Medical and Health.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Location offers a peaceful and spacious working environment, with easy access to the wider region via the nearby M40, a major arterial route south to Oxford and London.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "Wellesbourne main structure goes up",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "London can be reached by rail from Warwick Parkway with fast trains taking just over an hour.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "A short distance from Wellesbourne Airfield and WDP40, a business park including Aston Martin" +
        " Lagonda and Japanese entertainment company Namco Bandai.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The campus is in close proximity of the towns of Warwick and Leamington, major centres of future" +
        " mobility and creative and digital technologies, including the 'Silicon Spa' games development cluster, and the Gaydon engineering centres for Jaguar Land Rover and Aston Martin Lagonda.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Wellesbourne main structure goes up",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The city of Coventry is less than 25 minutes away and is one of the fastest growing cities in the" +
        " UK, with a projected growth rate of 23.8% by 2040.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "By joining us at Wellesbourne, occupiers will benefit from the University’s extensive network – both" +
        " academic and industrial. Warwick is home to some of the most highly respected researchers in their fields, with a proven record of successful collaborations with business.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Existing cluster includes Lotus LATC (Advanced Technology Centre), Rimac and Lyra Electronics.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "473 acres (191.4 hectares)",
    },
    {
      title: "Usage",
      value: "R&D, Innovation, Education",
    },
    {
      title: "Developer",
      value: "University of Warwick",
    },
    {
      title: "Authority",
      value: "Stratford-on-Avon District Council",
    },
    {
      title: "Local to",
      value: "Lotus Engineering, Aston Martin, Rimac",
    },
  ],
  contact: {
    website: {
      url: "https://warwick.ac.uk/business/wellesbourne",
      title: "The University Of Warwick",
    },
    email: "d.girdlestone@warwick.ac.uk",
    postcode: "CV35 9EF",
  },
};
