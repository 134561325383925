import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearClickedMarker,
  closePreviewPanel,
} from "../../actions/map-status/actions";
import PreviewPanelPresentational from "./presentational";
import MarkerController from "../../controllers/marker-controller";
import { setActiveContentId } from "../../actions/content-status/actions";
import { getImageDimensions } from "../../helpers/images";

class PreviewPanelFunctional extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      id: null,
      title: "",
    };

    this.state = { ...this.defaultState };

    this._getMarkerInformation = this._getMarkerInformation.bind(this);
  }

  componentDidMount() {
    this._getMarkerInformation();
  }

  componentDidUpdate(prevProps) {
    const { activeHotspot } = this.props;

    if (activeHotspot !== prevProps.activeHotspot) {
      this._getMarkerInformation();
    }
  }

  _getMarkerInformation() {
    const { activeHotspot } = this.props;
    if (activeHotspot) {
      const { id, name, image } = MarkerController.getMarkerById(activeHotspot);
      this.setState({
        id,
        title: name,
        image: image,
      });
    } else {
      this.setState({
        ...this.defaultState,
      });
    }
  }

  render() {
    const {
      isVisible,
      closePreviewPanel,
      continueToContent,
      activeHotspot,
    } = this.props;
    const { id, title, image } = this.state;
    let dimensions = null;
    if (image) {
      dimensions = getImageDimensions(
        image.thumbnail ? image.thumbnail : image.url,
      );
    }

    return (
      <PreviewPanelPresentational
        isVisible={isVisible}
        markerId={id}
        title={title}
        image={image}
        imageDimensions={dimensions}
        onClose={closePreviewPanel}
        onContinue={() => continueToContent(activeHotspot)}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isVisible: state.mapStatus.previewPanel.isVisible,
    activeHotspot: state.mapStatus.activeHotspot,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closePreviewPanel: () => {
      dispatch(closePreviewPanel());
      dispatch(clearClickedMarker());
    },
    continueToContent: hotspotId => {
      dispatch(setActiveContentId(hotspotId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewPanelFunctional);
