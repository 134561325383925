import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "../content-blocks.scss";

class VideoEmbedContentBlockPresentational extends PureComponent {
  render() {
    const {
      iframeContainerRef,
      iframeEmbedCode,
      aspectRatio,
      caption,
    } = this.props;

    const iframeClasses = classnames("content-block__iframe", {
      "content-block__iframe--4-3": aspectRatio === "4:3",
      "content-block__iframe--16-19": aspectRatio === "16:9",
    });

    return (
      <div className="content-block content-block--video-embed">
        <div
          ref={iframeContainerRef}
          className={iframeClasses}
          dangerouslySetInnerHTML={{ __html: iframeEmbedCode }}
        />
        {caption && <div className="content-block__caption">{caption}</div>}
      </div>
    );
  }
}

VideoEmbedContentBlockPresentational.propTypes = {
  iframeContainerRef: PropTypes.any,
  iframeEmbedCode: PropTypes.string,
  aspectRatio: PropTypes.oneOf(["4:3", "16:9"]),
  caption: PropTypes.string,
};

VideoEmbedContentBlockPresentational.defaultProps = {
  aspectRatio: "16:9",
};

export default VideoEmbedContentBlockPresentational;
