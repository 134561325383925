import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../assets/graphics/close.svg";
import "./close-button.scss";

class CloseButtonPresentational extends PureComponent {
  render() {
    const { className, ariaLabel, onClose, light } = this.props;
    const classes = classnames("close-btn", className, {
      "close-btn--light": light,
    });

    return (
      <button onClick={onClose} className={classes} aria-label={ariaLabel}>
        <CloseIcon />
      </button>
    );
  }
}

CloseButtonPresentational.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClose: PropTypes.func,
  light: PropTypes.bool,
};

CloseButtonPresentational.defaultProps = {
  light: false,
};

export default CloseButtonPresentational;
