import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/jlr-production-centre.jpg";
import Image2 from "../../assets/images/gaydon-lighthorne-heath.jpg";
import Image2Cropped from "../../assets/images/gaydon-lighthorne-heath-cropped.jpg";

export const gaydonLighthorneHeath = {
  id: 15,
  name: "Gaydon Lighthorne Heath Expansion Land",
  "short-description":
    "Employment expansion land located at J12 M40 at Gaydon, next to major HQ and Engineering Centres for Aston Martin Lagonda and Jaguar Land Rover.",
  image: {
    url: Image2,
    alt: "Gaydon Lighthorne Heath Expansion Land",
    thumbnail: Image2Cropped,
  },
  position: {
    top: 78,
    left: 62,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Well located development site strategically located between the motorsport cluster of Silverstone," +
        " and the automotive cluster around Coventry and Warwick/Leamington Spa.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "Jaguar Landrover Production Centre",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The site is next to the village of Gaydon, and British Motor Museum, the largest museum for British" +
        " made cars in the UK.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "British Motor Museum includes conferencing facilities for hire, and has outline consent for a hotel" +
        " development next door.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The site is next to the new expanded settlement of Gaydon Lighthorne Heath, delivering 3,000 new" +
        " homes.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "400 Acres (162 Hectares)",
    },
    {
      title: "Usage",
      value: "Employment, B1, B2",
    },
    {
      title: "Developer",
      value: "Bird Group",
    },
    {
      title: "Authority",
      value: "Stratford on Avon District Council",
    },
    {
      title: "Local to",
      value:
        "Jaguar Land Rover Design and Engineering HQ, Aston Martin Lagonda HQ",
    },
  ],
  contact: {
    website: {
      url: "http://www.thebirdgroup.co.uk/",
      title: "The Bird Group",
    },
    postcode: "CV35 0AE",
  },
};
