import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import LogoContentBlockPresentational from "./presentational";
import { getImageDimensions } from "../../../../helpers/images";

class LogoContentBlockFunctional extends PureComponent {
  render() {
    const { url, companyName } = this.props;
    const sizes = getImageDimensions(url);

    return (
      <LogoContentBlockPresentational
        url={url}
        companyName={companyName}
        dimensions={sizes}
      />
    );
  }
}

LogoContentBlockFunctional.propTypes = {
  url: PropTypes.string,
  companyName: PropTypes.string,
};

export default LogoContentBlockFunctional;
