import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/segro-park-coventry-gateway.jpg";
import Image2 from "../../assets/images/sergo-park-coventry-gateway.jpg";

export const segroParkCoventryGateway = {
  id: 10,
  name: "SEGRO Park Coventry Gateway",
  "short-description":
    "SEGRO Park Coventry Gateway is under construction and will deliver up to 3.7m sq ft of new industrial and logistics space to the south of Coventry Airport.<br /><br />The scheme has planning permission for 3.7m sq ft of manufacturing (B2) and warehouse (B8) employment space.<br /><br />The private estate, where buildings will be designed and constructed to the highest sustainable standards, will benefit from being surrounded by a newly created 235 acre community park.<br /><br />Space will be available from 2023. Early occupier interest is sought on a leasehold and freehold basis.",
  image: {
    url: Image2,
    alt: "Segro Park Coventry Gateway",
    thumbnail: Image2,
  },
  position: {
    top: 48,
    left: 59,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "A major industrial development site, it is locared next to Coventry Airport, which will be home to the new West Midlands Gigafactory, a 5.7m sqft facility for the production of EV batteries.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "SEGRO Park Coventry Gateway",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Local occupiers include Jaguar Land Rover, UK Battery Industrialisation Centre (UKBIC), Geely London EV Company, Daimler Benz Evobus and Meggitt.",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://www.youtube.com/embed/kQObK2pksRs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The Manufacturing Technology Centre, opened in 2009, is a UK mnufactuirng catapult centre, and works with industry to innovate manufacturing processes. It is close by at Ansty Technology Park.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "260 acres (105.2 hectares)",
    },
    {
      title: "Usage",
      value: "Industrial, R&D, B8, Hotel, Retail",
    },
    {
      title: "Developer",
      value: "SEGRO",
    },
    {
      title: "Authority",
      value: "Coventry City Council, Warwick Distrcit Council",
    },
    {
      title: "Local to",
      value: "UK Battery Industrialisation Centre (UKBIC), WM Gigafactory",
    },
  ],
  contact: {
    website: {
      url:
        "https://www.segro.com/property-search/estates/united-kingdom/national/coventry",
      title: "SEGRO Park Coventry Gateway",
    },
    postcode: "CV8 3BB",
  },
};
