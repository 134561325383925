import {
  CLEAR_CLICKED_MARKER,
  CLOSE_PREVIEW,
  SET_ACTIVE_PREVIEW,
  SET_CLICKED_MARKER,
  SET_ZOOM_LEVEL,
  TOGGLE_CONTACT_DETAILS,
} from "./constants";
import { MAX_MAP_ZOOM, MIN_MAP_ZOOM } from "../../constants/map-settings";

export const setActiveHotspotPreview = hotspotId => {
  return {
    type: SET_ACTIVE_PREVIEW,
    hotspotId,
  };
};

export const closePreviewPanel = () => {
  return {
    type: CLOSE_PREVIEW,
  };
};

export const setZoomLevel = level => {
  level = level < MIN_MAP_ZOOM ? MIN_MAP_ZOOM : level;
  level = level > MAX_MAP_ZOOM ? MAX_MAP_ZOOM : level;

  return {
    type: SET_ZOOM_LEVEL,
    level,
  };
};

export const setClickedMarker = hotspotId => {
  return {
    type: SET_CLICKED_MARKER,
    hotspotId,
  };
};

export const clearClickedMarker = () => {
  return {
    type: CLEAR_CLICKED_MARKER,
  };
};

export const toggleContactDetailsVisibility = () => {
  return {
    type: TOGGLE_CONTACT_DETAILS,
  };
};
