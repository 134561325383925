import {
  PARAGRAPH_CONTENT_TYPE,
  IMAGE_CONTENT_TYPE,
  LOGO_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";

import CreativeQuarterImage1 from "../../assets/images/leamington-spencer-yard.jpg";
import Image1Cropped from "../../assets/images/leamington-spencer-yard-cropped.png";
import Logo from "../../assets/images/creative-quarter-logo.png";
import Image2 from "../../assets/images/creative-quarter-frontview.jpg";
import Image3 from "../../assets/images/creative-quarter-sideview.jpg";

export const creativeQuarter = {
  id: 13,
  name: "Creative Quarter Royal Leamington Spa",
  "short-description":
    "Complex Development Projects Ltd (CDP) are in partnership with Warwick District Council to deliver an ambitious masterplan to regenerate the Old Town of Leamington Spa as a new Creative Quarter. It will regenerate and breathe new life into the Old Town of Leamington Spa whilst enhancing the economic development of the new town and joining up the two distinct areas.",
  image: {
    url: CreativeQuarterImage1,
    alt: "Spencer Yard Leamington Spa",
    thumbnail: Image1Cropped,
  },
  position: {
    top: 61,
    left: 56.5,
  },
  ecosystem: [
    {
      type: LOGO_CONTENT_TYPE,
      content: {
        url: Logo,
        companyName: "Creative Quarter Royal Leamington Spa",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Hotbed for Video Game Development and home to companies such as SEGA Hardlight, UBISOFT and Playground Games a Microsoft XBOX Studio",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://player.vimeo.com/video/394916264" width="640" height="360"' +
          ' frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Across Coventry & Warwickshire 15% of the uks development roles for video games are based here",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "1500 creative business across the district",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://player.vimeo.com/video/686143188" width="640" height="360"' +
          ' frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "Over 50+ Games studios just in the Silicon Spa area",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Home to 6000 University of Warwick Students",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "Home to 6000 University of Warwick Students",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "Regularly voted one of the best places to live in the UK",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "Regularly voted one of the best places to live in the UK",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "14 Acres (5.3 Hectares)",
    },
    {
      title: "Usage",
      value: "Office, Mixed Use, Residential",
    },
    {
      title: "Delivery",
      value:
        "Complex Development Projects Ltd in association with Warwick District Council",
    },
    {
      title: "Authority",
      value: "Warwick District Council",
    },
    {
      title: "Local to",
      value: "Ubisoft, SEGA Hardlight, Microsoft XBOX",
    },
  ],
  contact: {
    website: {
      url: "https://www.leamingtoncreativequarter.co.uk",
      title: "Creative Quarter Royal Leamington Spa",
    },
    postcode: "CV31 3SY",
    links: [
      {
        url:
          "https://complexdevelopmentprojects.co.uk/project/leamington-spa-creative-quarter/",
        title: "Complex Development Projects",
      },
      {
        url: "https://www.youtube.com/watch?v=VfpT1RgTzZQ",
        title: "1 Mill Street",
      },
      {
        url: "https://warwickinnovationdistrict.com/creative-futures-cohort-3/",
        title: "Creative Futures",
      },
      {
        url: "https://interactive-futures.com/",
        title: "Interactive Futures",
      },
    ],
  },
};
