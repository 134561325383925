import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MessageOverlay from "../message-overlay";
import { contactDetails } from "../../content/contact";
import "./contact-details-message.scss";

class ContactDetailsMessagePresentational extends PureComponent {
  render() {
    const { afterClosed, isOpen } = this.props;
    return (
      <MessageOverlay
        buttonText={"Close"}
        afterClosed={afterClosed}
        isOpen={isOpen}
      >
        <p
          className="contact-details-message-text"
          dangerouslySetInnerHTML={{ __html: contactDetails.content }}
        />
      </MessageOverlay>
    );
  }
}

ContactDetailsMessagePresentational.propTypes = {
  afterClosed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ContactDetailsMessagePresentational.defaultProps = {
  isOpen: false,
};

export default ContactDetailsMessagePresentational;
