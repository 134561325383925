import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TabsPresentational from "./presentational";
import { setActiveTabKey } from "../../actions/content-status/actions";

class TabsFunctional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      tabTitles: [],
      tabContentPanels: [],
      tabContentPanelHeight: 0,
    };

    this.contentPanelRefs = props.children.map(() => {
      return React.createRef();
    });

    this.tabChangeTimeout = null;

    this._extractTabs = this._extractTabs.bind(this);
    this._selectTitle = this._selectTitle.bind(this);
    this._getSizeOfContentPanels = this._getSizeOfContentPanels.bind(this);
  }

  componentDidMount() {
    this._extractTabs();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { scrollToAnimationTime, onChange } = this.props;
    const { activeIndex } = this.state;

    if (prevState.activeIndex !== activeIndex) {
      if (
        this.contentPanelRefs[activeIndex].current &&
        typeof onChange === "function"
      ) {
        const { clientHeight } = this.contentPanelRefs[activeIndex].current;
        onChange(clientHeight);

        if (prevState.tabContentPanelHeight < clientHeight) {
          this._getSizeOfContentPanels();
        } else {
          this.tabChangeTimeout = setTimeout(() => {
            this._getSizeOfContentPanels();
          }, scrollToAnimationTime);
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.tabChangeTimeout);
  }

  _extractTabs() {
    const { children } = this.props;
    let tabTitles = [];
    let tabContentPanels = [];

    React.Children.map(children, child => {
      if (child) {
        tabTitles.push({ title: child.props.title, icon: child.props.icon });
        tabContentPanels.push(child.props.children);
      }
    });

    this.setState(
      {
        tabTitles,
        tabContentPanels,
      },
      () => {
        this._getSizeOfContentPanels();
      },
    );
  }

  _selectTitle(index) {
    const { setActiveTabKey } = this.props;
    this.setState({
      activeIndex: index,
    });
    setActiveTabKey(index);
  }

  _getSizeOfContentPanels() {
    const { activeIndex, tabContentPanelHeight } = this.state;
    if (this.contentPanelRefs[activeIndex].current) {
      const { clientHeight } = this.contentPanelRefs[activeIndex].current;
      if (tabContentPanelHeight !== clientHeight) {
        this.setState({
          tabContentPanelHeight: clientHeight,
        });
      }
    }
  }

  render() {
    const { tabsRef } = this.props;
    const {
      tabTitles,
      tabContentPanels,
      activeIndex,
      tabContentPanelHeight,
    } = this.state;

    if (tabTitles.length === 0) {
      return null;
    }

    return (
      <TabsPresentational
        tabsRef={tabsRef}
        onTitleClick={this._selectTitle}
        tabTitles={tabTitles}
        tabChildren={tabContentPanels}
        activeIndex={activeIndex}
        tabContentPanelHeight={tabContentPanelHeight}
        refs={this.contentPanelRefs}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveTabKey: key => {
      dispatch(setActiveTabKey(key));
    },
  };
};

TabsFunctional.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
    PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  ]).isRequired,
  scrollToAnimationTime: PropTypes.number,
  onChange: PropTypes.func,
  tabsRef: PropTypes.any,
};

TabsFunctional.defaultProps = {
  scrollToAnimationTime: 0,
};

export default connect(null, mapDispatchToProps)(TabsFunctional);
