import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./content-panel.scss";
import { ImagePropType } from "../../constants/prop-types";
import Paragraph from "./content-blocks/paragraph";
import Image from "./content-blocks/full-width-image";
import Logo from "./content-blocks/logo";
import VideoEmbed from "./content-blocks/video-embed";
import Tabs from "../tabs";
import TabItem from "../tabs/tab-item";
import Button from "../button";
import MarkerWithTitle from "../marker-with-title";
import CloseButton from "../close-button";
import {
  IMAGE_CONTENT_TYPE,
  LOGO_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";

class ContentPanelPresentational extends Component {
  render() {
    const {
      id,
      title,
      mainImage,
      shortDescription,
      isVisible,
      onClose,
      stats,
      ecosystem,
      contact,
      tabsRef,
      onTabChange,
      scrollToAnimationTime,
      panelRef,
      panelContentRef,
    } = this.props;
    const classes = classnames("content-panel-container", {
      "content-panel-container--open": isVisible,
    });
    const tabsAreVisible = stats.length >= 1 || ecosystem || contact;
    const contactIsVisible =
      contact &&
      (contact.website || contact.postcode || contact.email || contact.links);

    return (
      <div className={classes}>
        <div
          className="content-panel"
          key={"content-panel" + id}
          ref={panelRef}
        >
          <div className="container">
            <div className="content-panel__close">
              {window.innerHeight > window.innerWidth ? (
                <Button
                  onClick={onClose}
                  className="content-panel__close--portrait"
                  type="secondary"
                  icon="back"
                >
                  Back to Map
                </Button>
              ) : (
                <CloseButton
                  className="content-panel__close--landscape"
                  onClose={onClose}
                  ariaLabel="Back to Map"
                />
              )}
            </div>
            <div className="content-panel__content" ref={panelContentRef}>
              <MarkerWithTitle markerNumber={id}>{title}</MarkerWithTitle>
              {mainImage && (
                <Image
                  key={mainImage.url}
                  url={mainImage.url}
                  alt={mainImage.alt}
                  caption={mainImage.caption}
                  bigUrl={mainImage.bigUrl}
                />
              )}
              <Paragraph textWithHtml={shortDescription} />
              {tabsAreVisible && (
                <Tabs
                  tabsRef={tabsRef}
                  key={"tabs-for-marker" + id}
                  onChange={onTabChange}
                  scrollToAnimationTime={scrollToAnimationTime}
                >
                  {Array.isArray(ecosystem) && ecosystem.length > 0 ? (
                    <TabItem title="Ecosystem" icon="world">
                      {ecosystem.map((contentBlock, index) => {
                        const { type, content } = contentBlock;
                        switch (type) {
                          case PARAGRAPH_CONTENT_TYPE:
                            return (
                              <Paragraph
                                key={"p-" + index}
                                textWithHtml={content}
                              />
                            );
                          case IMAGE_CONTENT_TYPE:
                            return (
                              <Image
                                key={"i-" + index}
                                url={content.url}
                                alt={content.alt}
                                bigUrl={content.bigUrl}
                              />
                            );
                          case LOGO_CONTENT_TYPE:
                            return (
                              <Logo
                                key={"i-" + index}
                                url={content.url}
                                companyName={content.companyName}
                              />
                            );
                          case VIDEO_CONTENT_TYPE:
                            return (
                              <VideoEmbed iframeEmbedCode={content.embed} />
                            );
                          default:
                            return null;
                        }
                      })}
                    </TabItem>
                  ) : (
                    <TabItem title="Ecosystem" icon="world">
                      {ecosystem}
                    </TabItem>
                  )}
                  {stats.length > 0 && (
                    <TabItem title="Statistics" icon="chart">
                      <h3>Statistics</h3>
                      <table>
                        <tbody>
                          {stats.map((stat, index) => {
                            return (
                              <tr key={"stat-" + index}>
                                <td className="table-first-col">
                                  {stat.title + ":"}
                                </td>
                                <td>{stat.value}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </TabItem>
                  )}
                  {contactIsVisible && (
                    <TabItem title="Contact" icon="link">
                      {(contact.website ||
                        contact.postcode ||
                        contact.email) && (
                        <div className="contact__block">
                          <h3>Contact Information</h3>
                          <table>
                            <tbody>
                              {contact.website && (
                                <tr>
                                  <td className="table-first-col">Website:</td>
                                  <td>
                                    <a
                                      href={contact.website.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {contact.website.title}
                                    </a>
                                  </td>
                                </tr>
                              )}
                              {contact.email && (
                                <tr>
                                  <td className="table-first-col">Email:</td>
                                  <td>
                                    <a href={"mailto:" + contact.email}>
                                      {contact.email}
                                    </a>
                                  </td>
                                </tr>
                              )}
                              {contact.postcode && (
                                <tr>
                                  <td className="table-first-col">Postcode:</td>
                                  <td>{contact.postcode}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {contact.links && (
                        <div className="contact__block">
                          <h3>Useful Links</h3>
                          <ul className="contact__links-list">
                            {contact.links.map((link, index) => {
                              return (
                                <li className="contact__links-list-item">
                                  <a
                                    key={"link-" + index}
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {link.title}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </TabItem>
                  )}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContentPanelPresentational.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  mainImage: PropTypes.shape(ImagePropType),
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  ecosystem: PropTypes.string,
  contact: PropTypes.shape({
    website: PropTypes.string,
    postcode: PropTypes.string,
  }),
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  panelRef: PropTypes.any,
  panelContentRef: PropTypes.any,
  tabsRef: PropTypes.any,
  onTabChange: PropTypes.func,
  scrollToAnimationTime: PropTypes.number,
};

ContentPanelPresentational.defaultProps = {
  isVisible: false,
  stats: [],
};

export default ContentPanelPresentational;
