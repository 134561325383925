import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./preview-panel.scss";
import Button from "../button";
import MarkerWithTitle from "../marker-with-title";
import CloseButton from "../close-button";
import { ImagePropType } from "../../constants/prop-types";

class PreviewPanelPresentational extends PureComponent {
  render() {
    const {
      markerId,
      isVisible,
      title,
      image,
      onClose,
      onContinue,
      imageDimensions,
    } = this.props;
    const hasDimensions = imageDimensions !== null;
    const classes = classnames("preview-panel-container", {
      "preview-panel-container--open": isVisible,
    });
    const imageContainerClasses = classnames("preview-panel__image-container", {
      "preview-panel__image-container--sized": hasDimensions,
    });
    const imageClasses = classnames("preview-panel__image", {
      "preview-panel__image--sized": hasDimensions,
    });
    let sizerStyles = {};

    if (hasDimensions) {
      sizerStyles = {
        paddingTop:
          (imageDimensions.height / imageDimensions.width) * 100 + "%",
      };
    }

    return (
      <div className={classes}>
        <div className="preview-panel">
          <div className="container preview-panel__content-container">
            <div className="preview-panel__content">
              <div className="preview-panel__header">
                <MarkerWithTitle markerNumber={markerId}>
                  {title}
                </MarkerWithTitle>
                {typeof onClose === "function" && (
                  <CloseButton
                    className="preview-panel__close"
                    onClose={onClose}
                    ariaLabel="Close Preview Panel"
                  />
                )}
              </div>
              <div className="preview-panel__footer">
                <div className={imageContainerClasses}>
                  {hasDimensions && (
                    <div
                      className="preview-panel__image-sizer"
                      style={sizerStyles}
                    />
                  )}
                  {image && (
                    <img
                      key={image.url}
                      src={image.thumbnail ? image.thumbnail : image.url}
                      className={imageClasses}
                      alt={image.alt}
                    />
                  )}
                </div>
                <div className="preview-panel__btn-container">
                  {typeof onContinue === "function" && (
                    <Button onClick={onContinue}>See More</Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PreviewPanelPresentational.propTypes = {
  markerId: PropTypes.number,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.shape(ImagePropType),
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  imageDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
};

PreviewPanelPresentational.defaultProps = {
  isVisible: false,
  imageDimensions: null,
};

export default PreviewPanelPresentational;
