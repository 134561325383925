import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ReactComponent as BackIcon } from "../../assets/graphics/back-arrow.svg";
import "./button.scss";

class ButtonPresentational extends PureComponent {
  render() {
    const { type, icon, children, onClick, className } = this.props;
    const classes = classnames("btn", className, {
      "btn--secondary": type === "secondary",
      "btn--light": type === "light",
    });

    return (
      <button className={classes} onClick={onClick}>
        {(() => {
          switch (icon) {
            case "back":
              return <BackIcon />;
            default:
              return null;
          }
        })()}
        {children}
      </button>
    );
  }
}

ButtonPresentational.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "light"]),
  icon: PropTypes.oneOf(["back"]),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ButtonPresentational.defaultProps = {
  type: "primary",
};

export default ButtonPresentational;
