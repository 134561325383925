import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";

import Image1 from "../../assets/images/bmw-new-generation-engines.jpg";
import Image3 from "../../assets/images/solihuill-hs2.jpg";
import Image4 from "../../assets/images/hams-hall-map.png";
import Image4Cropped from "../../assets/images/hams-hall-map-cropped.jpg";

export const prologisHamsHall = {
  id: 1,
  name: "Prologis Hams Hall",
  "short-description":
    "Prologis Park Hams Hall is a major Midlands manufacturing & distribution hub centrally positioned in the West Midlands to serve the UK and international markets, currently undergoing expansion.",
  image: {
    url: Image4,
    bigUrl: Image4,
    thumbnail: Image4Cropped,
    alt: "Hams Hall",
  },
  position: {
    top: 13,
    left: 33,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Six motorways, two airports and a direct-to-seaport rail freight terminal - you're better connected as Prologis Park Hams Hall",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image1,
        alt: "New Generation BMW Engines",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Local occupiers include automotive OEMs BMW Hams Hall, Sertec and the new Jaguar Land Rover Battery" +
        " Assembly Centre",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Short drive from Birmingham International Airport and HS2 Hub Station",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "HS2 Connection",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Close to The Belfry golf course, the NEC and Resorts World, and the new Emerge Surf, wave park.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "Units under construction on a speculative development basis.",
    },
  ],
  stats: [
    {
      title: "Statistics",
      value: "Space available sqft and sqm, recent investments size",
    },
    {
      title: "Size",
      value: "886,970 sqft (82,402.2 sqm)",
    },
    {
      title: "Usage",
      value: "B2, B8",
    },
    {
      title: "Developer",
      value: "Prologis",
    },
    {
      title: "Authority",
      value: "North Warwickshire Borough Council",
    },
    {
      title: "Local to",
      value: "BMW, JLR Battery Assembly",
    },
  ],
  contact: {
    website: {
      url: "https://www.prologis.co.uk/parks/prologis-park-hams-hall",
      title: "Prologis Park Hams Hall",
    },
    postcode: "B46 1GB",
    links: [
      {
        url: "https://prologis.co.uk/park-life/",
        title: "Prologis Park Life",
      },
    ],
  },
};
