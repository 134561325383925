import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as ZoomInIcon } from "../../../assets/graphics/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../../../assets/graphics/zoom-out.svg";
import { ReactComponent as InfoIcon } from "../../../assets/graphics/information.svg";
import "./control-button.scss";

class ControlButtonPresentational extends PureComponent {
  render() {
    const { onClick, isEnabled, type } = this.props;
    const classes = classnames("control-btn", {
      "control-btn--disabled": !isEnabled,
    });

    return (
      <button
        className={classes}
        onClick={isEnabled ? onClick : e => e.preventDefault()}
      >
        <div className="control-btn__icon">
          {(() => {
            switch (type) {
              case "zoom-in":
                return <ZoomInIcon />;
              case "zoom-out":
                return <ZoomOutIcon />;
              case "info":
                return <InfoIcon />;
              default:
                return null;
            }
          })()}
        </div>
      </button>
    );
  }
}

ControlButtonPresentational.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["zoom-in", "zoom-out", "info"]).isRequired,
  isEnabled: PropTypes.bool,
};

ControlButtonPresentational.defaultProps = {
  isEnabled: false,
};

export default ControlButtonPresentational;
