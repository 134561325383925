import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ControlButton from "../../control-button";
import "./zoom-controls.scss";

class ZoomControlsPresentational extends PureComponent {
  render() {
    const { onZoomIn, onZoomOut, zoomInEnabled, zoomOutEnabled } = this.props;
    return (
      <div className="zoom-controls">
        <ControlButton
          onClick={zoomInEnabled ? onZoomIn : undefined}
          type="zoom-in"
          isEnabled={zoomInEnabled}
        />
        <ControlButton
          onClick={zoomOutEnabled ? onZoomOut : undefined}
          type="zoom-out"
          isEnabled={zoomOutEnabled}
        />
      </div>
    );
  }
}

ZoomControlsPresentational.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  zoomInEnabled: PropTypes.bool,
  zoomOutEnabled: PropTypes.bool,
};

ZoomControlsPresentational.defaultProps = {
  zoomInEnabled: false,
  zoomOutEnabled: false,
};

export default ZoomControlsPresentational;
