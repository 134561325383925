import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ControlButton from "../control-button";
import "./extra-controls.scss";

class ExtraControlsPresentational extends PureComponent {
  render() {
    const { onContactDetailsClick } = this.props;
    return (
      <div className="extra-controls">
        <ControlButton type="info" onClick={onContactDetailsClick} isEnabled />
      </div>
    );
  }
}

ExtraControlsPresentational.propTypes = {
  onContactDetailsClick: PropTypes.func.isRequired,
};

export default ExtraControlsPresentational;
