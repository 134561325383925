import { markers } from "../content/makers";

class MarkerController {
  constructor() {
    this.markersObj = markers;
    this.markersArr = Object.values(this.markersObj);
    this.sortedArr = null;
  }

  get markers() {
    return this.markersArr;
  }

  get markersSortedByYPosition() {
    if (this.sortedArr === null) {
      let markers = [...this.markers];

      return markers.sort((a, b) => {
        if (a.position.top < b.position.top) {
          return -1;
        } else if (a.position.top > b.position.top) {
          return 1;
        }
        return 0;
      });
    }
    return this.sortedArr;
  }

  getMarkerById(markerId) {
    return this.markersObj[markerId];
  }
}

const thisMarkerController = new MarkerController();
export default thisMarkerController;
