import React, { Component } from "react";
import { connect } from "react-redux";
import * as panzoom from "panzoom";
import MapPresentational from "./presentational";
import {
  clearClickedMarker,
  closePreviewPanel,
  setActiveHotspotPreview,
  setClickedMarker,
  setZoomLevel,
} from "../../actions/map-status/actions";
import {
  closeContentPanel,
  setActiveContentId,
} from "../../actions/content-status/actions";
import { MAX_MAP_ZOOM, MIN_MAP_ZOOM } from "../../constants/map-settings";
import MarkerController from "../../controllers/marker-controller";

class MapFunctional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markerOffset: 1,
      svgWidth: 0,
      svgHeight: 0,
      clickedMarker: null,
      mapHasLoaded: false,
    };

    this.map = null;
    this.mapRef = React.createRef();
    this.mapSvgRef = React.createRef();

    this.animationTimeout = null;
    this.zoomTimeout = null;
    this.fixTimeout = null;

    this._initialiseMap = this._initialiseMap.bind(this);
    this._registerEventListeners = this._registerEventListeners.bind(this);
    this._blockPanning = this._blockPanning.bind(this);
    this._allowPanning = this._allowPanning.bind(this);
    this._openAppropriatePanel = this._openAppropriatePanel.bind(this);
    this._openPreviewPanel = this._openPreviewPanel.bind(this);
    this._openContentPanel = this._openContentPanel.bind(this);
    this._closePreviewPanel = this._closePreviewPanel.bind(this);
    this._closeAllPanels = this._closeAllPanels.bind(this);
    this._updateZoomLevel = this._updateZoomLevel.bind(this);
    this._clearClickedMarker = this._clearClickedMarker.bind(this);
    this._onMapClick = this._onMapClick.bind(this);
    this._handleImageLoad = this._handleImageLoad.bind(this);
    this.resizeMarkerContainer = this.resizeMarkerContainer.bind(this);
  }

  componentDidMount() {
    this._initialiseMap();
    this.resizeMarkerContainer();
    this._registerEventListeners();
  }

  componentDidUpdate(prevProps) {
    const { zoomLevel } = this.props;

    if (this.map !== null && prevProps.zoomLevel !== zoomLevel) {
      const { scale } = this.map.getTransform();
      if (scale !== zoomLevel) {
        const { width, height } = this.mapRef.current.getBoundingClientRect();
        const { x, y } = this.map.getTransform();
        const centreXWithTransform = width / 2 + x;
        const centreYWithTransform = height / 2 + y;
        const percentChange = zoomLevel / scale;
        this.map.zoomTo(
          centreXWithTransform,
          centreYWithTransform,
          percentChange,
        );
      }
    }
  }

  componentWillUnmount() {
    this._clearEventListeners();
    clearTimeout(this.animationTimeout);
    clearTimeout(this.zoomTimeout);
    clearTimeout(this.fixTimeout);
  }

  _registerEventListeners() {
    window.addEventListener("resize", this.resizeMarkerContainer);
    window.addEventListener("resize", this._closePreviewPanel);

    this.map.on("pan", this._closeAllPanels);
    this.map.on("zoom", this._closeAllPanels);
    this.map.on("pan", this._clearClickedMarker);
    this.map.on("zoom", this._clearClickedMarker);
    this.map.on("zoom", this._updateZoomLevel);
  }

  _clearEventListeners() {
    window.removeEventListener("resize", this.resizeMarkerContainer);
    window.removeEventListener("resize", this._closePreviewPanel);

    this.map.off("pan", this._closeAllPanels);
    this.map.off("zoom", this._closeAllPanels);
    this.map.off("pan", this._clearClickedMarker);
    this.map.off("zoom", this._clearClickedMarker);
    this.map.off("zoom", this._updateZoomLevel);
  }

  _initialiseMap() {
    this.map = panzoom(this.mapRef.current, {
      bounds: true,
      boundsPadding: 1,
      maxZoom: MAX_MAP_ZOOM,
      minZoom: MIN_MAP_ZOOM,
    });
  }

  _updateZoomLevel() {
    const { zoomLevel, updateZoomLevel } = this.props;
    const { scale } = this.map.getTransform();
    this.setState({
      markerOffset: 1 / scale,
    });

    clearTimeout(this.zoomTimeout);
    this.zoomTimeout = setTimeout(() => {
      if (zoomLevel !== scale) {
        updateZoomLevel(scale);
      }
    }, 100);
  }

  _blockPanning() {
    if (this.map !== null) {
      this.map.pause();
    }
  }

  _allowPanning() {
    if (this.map !== null) {
      this.map.resume();
    }
  }

  _openAppropriatePanel(hotspotId) {
    const { setClickedMarker, clearClickedMarker, clickedMarker } = this.props;

    if (clickedMarker === hotspotId) {
      this._closeAllPanels();
      clearClickedMarker();
      return null;
    } else {
      setClickedMarker(hotspotId);
    }

    if (window.innerWidth > window.innerHeight) {
      this._openContentPanel(hotspotId);
    } else {
      this._openPreviewPanel(hotspotId);
    }
  }

  _openPreviewPanel(hotspotId) {
    const {
      previewPanelIsVisible,
      closePreviewPanel,
      openPreviewPanel,
    } = this.props;
    if (previewPanelIsVisible) {
      closePreviewPanel();
      this.animationTimeout = setTimeout(() => {
        openPreviewPanel(hotspotId);
      }, 250);
    } else {
      openPreviewPanel(hotspotId);
    }
  }

  _openContentPanel(hotspotId) {
    const {
      contentPanelIsVisible,
      closeContentPanel,
      openContentPanel,
    } = this.props;

    if (contentPanelIsVisible) {
      closeContentPanel();
      this.animationTimeout = setTimeout(() => {
        openContentPanel(hotspotId);
      }, 250);
    } else {
      openContentPanel(hotspotId);
    }
  }

  _closePreviewPanel() {
    const { previewPanelIsVisible, closePreviewPanel } = this.props;
    if (previewPanelIsVisible) {
      closePreviewPanel();
    }
  }

  _closeAllPanels() {
    const {
      previewPanelIsVisible,
      contentPanelIsVisible,
      closePreviewPanel,
      closeContentPanel,
    } = this.props;

    if (previewPanelIsVisible) {
      closePreviewPanel();
    }

    if (contentPanelIsVisible) {
      closeContentPanel();
    }
  }

  _clearClickedMarker() {
    const { clearClickedMarker } = this.props;
    clearClickedMarker();
  }

  _onMapClick() {
    this._closeAllPanels();
    this._clearClickedMarker();
  }

  resizeMarkerContainer() {
    this.setState({
      svgWidth: this.mapSvgRef.current.clientWidth,
      svgHeight: this.mapSvgRef.current.clientHeight,
    });
  }

  _handleImageLoad() {
    this.resizeMarkerContainer();
    this.setState({
      mapHasLoaded: true,
    });

    // This triggers a second resize after load on IE
    // To fix an issue with incorrect marker placement.
    if (!!window.document.documentMode) {
      this.fixTimeout = setTimeout(() => {
        this.resizeMarkerContainer();
      }, 50);
    }
  }

  render() {
    const { svgWidth, svgHeight, markerOffset, mapHasLoaded } = this.state;
    const { clickedMarker } = this.props;

    return (
      <MapPresentational
        markersAreVisible={mapHasLoaded}
        markers={MarkerController.markersSortedByYPosition}
        mapRef={this.mapRef}
        mapSvgRef={this.mapSvgRef}
        markerContainerSize={{ width: svgWidth, height: svgHeight }}
        onMapClick={this._onMapClick}
        onMarkerClick={this._openAppropriatePanel}
        onMarkerMouseDown={this._blockPanning}
        onMarkerMouseUp={this._allowPanning}
        onImageLoad={this._handleImageLoad}
        markerOffset={markerOffset}
        clickedMarker={clickedMarker}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    previewPanelIsVisible: state.mapStatus.previewPanel.isVisible,
    contentPanelIsVisible: state.contentStatus.isVisible,
    zoomLevel: state.mapStatus.mapConfig.zoomLevel,
    clickedMarker: state.mapStatus.clickedHotspot,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openPreviewPanel: hotspotId => {
      dispatch(setActiveHotspotPreview(hotspotId));
    },
    openContentPanel: hotspotId => {
      dispatch(setActiveContentId(hotspotId));
    },
    closePreviewPanel: () => {
      dispatch(closePreviewPanel());
    },
    closeContentPanel: () => {
      dispatch(closeContentPanel());
    },
    updateZoomLevel: level => {
      dispatch(setZoomLevel(level));
    },
    setClickedMarker: hotspotId => {
      dispatch(setClickedMarker(hotspotId));
    },
    clearClickedMarker: () => {
      dispatch(clearClickedMarker());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapFunctional);
