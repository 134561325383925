import {
  IMAGE_CONTENT_TYPE,
  LOGO_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/qrtc-quinton-rail-technology-centre-long-marston.png";
import Image1Cropped from "../../assets/images/qrtc-quinton-rail-technology-centre-long-marston-cropped.png";
import Image2 from "../../assets/images/qrct-bcrre-university-of-birmingham.jpg";
import Image3 from "../../assets/images/long-marton-rail.jpg";

export const longMarstonRailCentre = {
  id: 16,
  name: "Long Marston Rail Innovation Centre",
  "short-description":
    "The Long Marston site is connected to the mainline rail network, and covers 135 acres including of 12 miles of storage sidings, a two-mile test-track loop, a short length of electrified line and a number of rail served light maintenance structures.",
  image: {
    url: Image1,
    alt: "Long Marston Rail Innovation Centre",
    thumbnail: Image1Cropped,
  },
  position: {
    top: 90,
    left: 20,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Rail leasing and technology company Porterbrook has taken a 15 year lease at the site.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "BCRRE University of Birmingham",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Porterbrook plans to work with the rail supply chain and academic bodies, as well as creating new partnerships with technology leaders from other sectors, so that Long Marston can play a role in helping to improve the railway for both passengers and freight customers.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The centre is home to a number of rail technology businesses, including Chrysallis and TDI Greenway.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The site has been used by the University of Birmingham BCRRE with Porterbrook to test and develop the hydrogen propelled Hydroflex train.",
    },
    {
      type: LOGO_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "Rail Live 2020",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The site used to be known as the Quinton Rail Technology Centre (QRTC).",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "The site hosts Bauer Media's annual Rail Live, the UK's largest outdoor rail technology conference and trade show.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "135 acres",
    },
    {
      title: "Usage",
      value: "Employment, B1, B2",
    },
    {
      title: "Developer",
      value: "Motorail/ (from Q2 2021 Porterbrook)",
    },
    {
      title: "Authority",
      value: "Stratford on Avon District Council",
    },
    {
      title: "Local to",
      value:
        "Chrysallis Rail, Unipart, Transport Design International, UK Tram",
    },
  ],
  contact: {
    website: {
      url: "https://www.raillive.org.uk/",
      title: "Rail Live",
    },
    postcode: "CV37 8PL",
    links: [
      {
        url: "https://www.youtube.com/watch?v=_Xfuxxx8ZD0",
        title: "Hydroflex",
      },
      {
        url: "https://www.youtube.com/watch?v=U4_NwysnEl8",
        title: "Vivarail",
      },
    ],
  },
};
