import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Button from "../button";
import "./message-overlay.scss";

class MessageOverlayPresentational extends PureComponent {
  render() {
    const {
      onClose,
      isExiting,
      fadeOutDuration,
      animationOutDuration,
      children,
      buttonText,
    } = this.props;
    const classes = classnames("message-overlay-container", {
      "message-overlay-container--exiting": isExiting,
    });
    const styles = {
      transitionDuration: fadeOutDuration + "ms",
      transitionDelay: isExiting ? animationOutDuration / 4 + "ms" : 0,
    };
    const contentStyles = {
      transitionDuration: fadeOutDuration + "ms",
    };

    return (
      <div className={classes} style={styles}>
        <div className="message-overlay-animator" style={contentStyles}>
          <div className="message-overlay">
            <div className="message-overlay__padded">
              <div className="message-overlay__content">
                {children}
                <div className="message-overlay__button">
                  <Button onClick={onClose} type="light">
                    {buttonText}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MessageOverlayPresentational.propTypes = {
  children: PropTypes.any.isRequired,
  buttonText: PropTypes.string.isRequired,
};

MessageOverlayPresentational.defaultProps = {
  buttonText: "Close",
};

export default MessageOverlayPresentational;
