import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/wm-gigafactory-front.jpg";
import Image1Cropped from "../../assets/images/wm-gigafactory-cropped.jpg";
import Image2 from "../../assets/images/wm-gigafactory-aerial.jpg";

export const wmGigafactory = {
  id: 9,
  name: "West Midlands Gigafactory",
  "short-description":
    "West Midlands Gigafactory’s mission is to create the UK’s largest battery manufacturing facility in the heart of the UK automotive industry. The West Midlands Gigafactory is a strategically crucial investment for the region and the UK, and the goal is to help drive the world’s transition to sustainable energy through electric vehicles.",
  image: {
    url: Image1,
    alt: "West Midlands Gigafactory",
    thumbnail: Image1Cropped,
  },
  position: {
    top: 44,
    left: 64,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "At full capacity, the Gigafactory will be able to produce up to 60GWh - enough to power 600,000 electric vehicles per year - and will be ready for production in 2025.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Classic Works Opening",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "It will be one of the largest new industrial facilities of any kind in the UK. The project will inject a £2.5bn investment into the region and create up to 6,000 new highly skilled jobs directly and many more in the supply chain.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "This site is located next to UKBIC, the £130m UK Battery Industrialisation Centre, the national centre for battery technology manufacturing scale up.",
    },
    {
      type: VIDEO_CONTENT_TYPE,
      content: {
        embed:
          '<iframe src="https://www.youtube.com/embed/kQObK2pksRs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "It is also located close to Jaguar Land Rover Whitley Powertrain Centre, Geely London EV Company HQ and the MTC at Ansty Technology Park.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "5.7m sqft (530,000sqm)",
    },
    {
      title: "Usage",
      value: "B1, B2",
    },
    {
      title: "Developer",
      value: "Rigby Group/ Coventry City Council JV",
    },
    {
      title: "Authority",
      value: "Warwick District Council",
    },
    {
      title: "Local To",
      value:
        "Jaguar Land Rover, BMW Hams Hall, London EV Company, Aston Martin Lagonda",
    },
  ],
  contact: {
    website: {
      url: "https://www.ukgigafactory.com/",
      title: "West Midlands Gigafactory",
    },
    links: [
      {
        url: "https://youtu.be/kQObK2pksRs",
        title: "UKBIC",
      },
    ],
  },
};
