import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleContactDetailsVisibility } from "../../../actions/map-status/actions";
import ExtraControlsPresentational from "./presentational";

class ExtraControlsFunctional extends Component {
  render() {
    const { openContactDetails } = this.props;
    return (
      <ExtraControlsPresentational onContactDetailsClick={openContactDetails} />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openContactDetails: () => {
      dispatch(toggleContactDetailsVisibility());
    },
  };
};

export default connect(null, mapDispatchToProps)(ExtraControlsFunctional);
