import Image1 from "../../assets/images/loades-eco-park-1.jpg";
import Image1Cropped from "../../assets/images/loades-eco-park-1-cropped.png";
import Image2 from "../../assets/images/loades-eco-park-2.jpg";
import Image3 from "../../assets/images/loades-eco-park-may.jpg";
import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";

export const loadesEcoParc = {
  id: 4,
  name: "Loades Eco Parc",
  "short-description":
    "Loades Eco Parc is a modern 15 acre business park aimed at technology businesses, incorporating a high quality eco-design with PV (solar) panels and EV charging infrastructure. It is located a short drive from J3 of the M6.",
  image: {
    url: Image1,
    alt: "Loades Eco Parc",
    thumbnail: Image1Cropped,
  },
  position: {
    top: 27,
    left: 62,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Modern light industrial unit scheme next to Bayton Road Industrial Estate.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "Loades Eco Parc May 2018",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Located next to the former home of Abbey Panels, where the bodies of iconic cars like the Jagaur E-type and XJ-220, and Le Mans Ford GT-40, were manufactured, and components for the Harrier jump-jet.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Loades Eco Parc",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Today, local companies include Brose, the German tier 1 automotive supplier and Naysmith the defence and aerospace supplier.",
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "Close to UKBIC and the new West Midlands Gigafactory.",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "15 Acres (6 Hectares)",
    },
    {
      title: "Usage",
      value: "B1, B2",
    },
    {
      title: "Developer",
      value: "Loades",
    },
    {
      title: "Authority",
      value: "Nuneaton & Bedworth Borough Council",
    },
    {
      title: "Local to",
      value: "Brose, Naysmyth, OLEO International, Mecalac",
    },
  ],
  contact: {
    website: {
      url: "http://www.loadesecoparc.co.uk/",
      title: "Loades Eco Parc",
    },
    postcode: "CV7 9FW",
    links: [
      {
        url: "https://www.ukgigafactory.com/",
        title: "WM Gigafactory",
      },
      {
        url: "https://youtu.be/kQObK2pksRs",
        title: "UKBIC",
      },
    ],
  },
};
