import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "../content-blocks.scss";
import { getSizerStylesFromDimensions } from "../../../../helpers/images";

class LogoContentBlockPresentational extends PureComponent {
  render() {
    const { url, companyName, dimensions } = this.props;
    const hasDimensions = dimensions !== null;
    const logoSizerStyles = getSizerStylesFromDimensions(dimensions);
    const logoContainerClasses = classnames("content-block__logo-container", {
      "content-block__logo-container--sized": hasDimensions,
    });
    const logoContainerStyles = {
      maxWidth: dimensions.width,
    };

    return (
      <div className="content-block content-block--logo">
        <div className={logoContainerClasses} style={logoContainerStyles}>
          {hasDimensions && (
            <div
              className="content-block__image-sizer"
              style={logoSizerStyles}
            />
          )}
          <img
            src={url}
            className="content-block--logo__image"
            alt={companyName}
          />
        </div>
      </div>
    );
  }
}

LogoContentBlockPresentational.propTypes = {
  url: PropTypes.string,
  companyName: PropTypes.string,
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
};

LogoContentBlockPresentational.defaultProps = {
  dimensions: null,
};

export default LogoContentBlockPresentational;
