import { createStore } from "redux";
import { allReducers } from "./actions/allReducers";

export const store = createStore(
  allReducers,
  undefined,
  window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : a => a,
);
