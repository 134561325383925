import {
  CLOSE_CONTENT_PANEL,
  SET_ACTIVE_CONTENT_ID,
  SET_ACTIVE_TAB_KEY,
} from "./constants";

const defaultContentStatus = {
  activeContentId: null,
  activeTabKey: 0,
  isVisible: false,
};

export const contentStatus = (state = defaultContentStatus, action) => {
  switch (action.type) {
    case SET_ACTIVE_CONTENT_ID:
      return {
        ...state,
        activeContentId: action.contentId,
        isVisible: true,
      };
    case CLOSE_CONTENT_PANEL:
      return {
        ...state,
        activeTabKey: 0,
        isVisible: false,
      };
    case SET_ACTIVE_TAB_KEY:
      return {
        ...state,
        activeTabKey: action.key,
      };
    default:
      return state;
  }
};
