import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import MarkerController from "../../controllers/marker-controller";
import { closeContentPanel } from "../../actions/content-status/actions";
import ContentPanelPresentational from "./presentational";
import { clearClickedMarker } from "../../actions/map-status/actions";

class ContentPanelFunctional extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      id: null,
      title: "",
      image: null,
      shortDescription: "",
      stats: [],
      ecosystem: null,
      contact: null,
      activeTabIndex: 0,
    };

    this.state = { ...this.defaultState };

    this.contentPanel = React.createRef();
    this.contentPanelContent = React.createRef();
    this.tabs = React.createRef();
    this.contentPanelPadding = 0;

    this.scrollToAnimationTime = 600;

    this._getContent = this._getContent.bind(this);
    this._onTabChange = this._onTabChange.bind(this);
    this._calculatePadding = this._calculatePadding.bind(this);
  }

  componentDidMount() {
    this._getContent();
    this._calculatePadding();
  }

  componentDidUpdate(prevProps) {
    const { activeContentId } = this.props;

    if (activeContentId !== prevProps.activeContentId) {
      this._getContent();
      this._trackMarker();
    }
  }

  _calculatePadding() {
    const styles = window.getComputedStyle(this.contentPanel.current);
    this.contentPanelPadding = parseInt(styles.paddingTop);
  }

  _getContent() {
    const { activeContentId } = this.props;

    if (activeContentId) {
      const marker = MarkerController.getMarkerById(activeContentId);
      this.setState({
        id: marker.id,
        title: marker.name,
        image: { ...marker.image },
        shortDescription: marker["short-description"],
        stats: [...marker.stats],
        ecosystem: marker.ecosystem,
        contact: { ...marker.contact },
      });
    } else {
      this.setState({
        ...this.defaultState,
      });
    }
  }

  _trackMarker() {
    const { id, title } = this.state;

    ReactGA.event({
      category: "Be Here Interactive Map",
      action: "Marker Visited",
      label: id + " - " + title,
    });
  }

  _onTabChange(newTabHeight) {
    const contentOffset = this.contentPanelContent.current.offsetTop;
    const tabOffset = this.tabs.current.offsetTop + contentOffset;
    const scrollPosition = this.contentPanel.current.scrollTop;
    const panelHeight = this.contentPanel.current.clientHeight;
    const panelBottom = panelHeight + scrollPosition;

    if (tabOffset + newTabHeight < panelBottom) {
      const scrollDiff =
        tabOffset + newTabHeight + this.contentPanelPadding - panelHeight;
      this.contentPanel.current.scrollTo({
        left: 0,
        top: scrollDiff,
        behavior: "smooth",
      });
    }
  }

  render() {
    const { closePanel, isVisible } = this.props;
    const {
      id,
      title,
      image,
      shortDescription,
      stats,
      ecosystem,
      contact,
    } = this.state;

    if (image && image.alt === undefined) {
      image.alt = "";
    }

    return (
      <ContentPanelPresentational
        onClose={closePanel}
        isVisible={isVisible}
        id={id}
        title={title}
        mainImage={image}
        shortDescription={shortDescription}
        stats={stats}
        ecosystem={ecosystem}
        contact={contact}
        tabsRef={this.tabs}
        onTabChange={this._onTabChange}
        scrollToAnimationTime={this.scrollToAnimationTime}
        panelRef={this.contentPanel}
        panelContentRef={this.contentPanelContent}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    activeContentId: state.contentStatus.activeContentId,
    isVisible: state.contentStatus.isVisible,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closePanel: () => {
      dispatch(closeContentPanel());
      dispatch(clearClickedMarker());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentPanelFunctional);
