import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ReactComponent as MapMarker } from "../../../assets/graphics/map-marker.svg";
import { ReactComponent as MapMarkerShadow } from "../../../assets/graphics/map-marker-shadow.svg";
import "./map-marker.scss";

class MapMarkerPresentational extends PureComponent {
  render() {
    const {
      label,
      isActive,
      position,
      onClick,
      onMouseDown,
      onMouseUp,
      scaleOffset,
    } = this.props;
    const style = {
      ...position,
      transform:
        "translateX(-50%) translateY(-100%) scale(" + scaleOffset + ")",
    };
    const classes = classnames("map-marker", {
      "map-marker--active": isActive,
    });
    return (
      <button
        className={classes}
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchEnd={e => {
          e.stopPropagation();
          onMouseUp();
          onClick();
        }}
        style={style}
      >
        <div className="map-marker__marker">
          <MapMarker />
        </div>
        <div className="map-marker__shadow">
          <MapMarkerShadow />
        </div>
        <div className="map-marker__label">{label}</div>
      </button>
    );
  }
}

MapMarkerPresentational.propTypes = {
  label: PropTypes.number,
  isActive: PropTypes.bool,
  position: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  scaleOffset: PropTypes.number,
};

MapMarkerPresentational.defaultProps = {
  isActive: false,
  scaleOffset: 1,
};

export default MapMarkerPresentational;
