import { prologisHamsHall } from "./marker-content/1-prologis-hams-hall";
import { miraTechnology } from "./marker-content/2-mira-technology-park";
import { transformNuneaton } from "./marker-content/3-transforming-nuneaton";
import { loadesEcoParc } from "./marker-content/4-loades-eco-parc";
import { friargateCoventry } from "./marker-content/5-friargate-coventry";
import { daimlerWharf } from "./marker-content/6-daimler-wharf";
import { collectionsCentre } from "./marker-content/7-collections-centre";
import { prosperoAnsty } from "./marker-content/8-prospero-ansty";
import { wmGigafactory } from "./marker-content/9-wm-gigafactory";
import { segroParkCoventryGateway } from "./marker-content/10-segro-park-coventry-gateway";
import { abbeyPark } from "./marker-content/11-abbey-park";
import { stoneleighPark } from "./marker-content/12-stoneleigh-park";
import { creativeQuarter } from "./marker-content/13-creative-quarter";
import { wellesbourneInnovation } from "./marker-content/14-wellesbourne-innovation-campus";
import { gaydonLighthorneHeath } from "./marker-content/15-gaydon-lighthorne-heath";
import { longMarstonRailCentre } from "./marker-content/16-long-marston-rail-centre";

export const markers = {
  "1": prologisHamsHall,
  "2": miraTechnology,
  "3": transformNuneaton,
  "4": loadesEcoParc,
  "5": friargateCoventry,
  "6": daimlerWharf,
  "7": collectionsCentre,
  "8": prosperoAnsty,
  "9": wmGigafactory,
  "10": segroParkCoventryGateway,
  "11": abbeyPark,
  "12": stoneleighPark,
  "13": creativeQuarter,
  "14": wellesbourneInnovation,
  "15": gaydonLighthorneHeath,
  "16": longMarstonRailCentre,
};
