import React, { PureComponent } from "react";
import "./loader.scss";

class LoaderPresentational extends PureComponent {
  render() {
    return <div className="loader" />;
  }
}

export default LoaderPresentational;
