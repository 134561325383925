import {
  IMAGE_CONTENT_TYPE,
  PARAGRAPH_CONTENT_TYPE,
} from "../../constants/content-types";
import Image1 from "../../assets/images/mira-technology-park.jpg";
import Image1Cropped from "../../assets/images/mira-technology-park-cropped.png";
import Image2 from "../../assets/images/facilities-mira-technology-institute.jpg";
import Image3 from "../../assets/images/mira-technology-park-test-track-facilities.jpg";
import Image4 from "../../assets/images/mira-technology-park-plug-and-play.jpg";
import Image5 from "../../assets/images/mira-cgi-map-top-view.jpg";
import Image6 from "../../assets/images/mira-cgi.jpg";

export const miraTechnology = {
  id: 2,
  name: "MIRA Technology Park and MIRA Technology Park South Site",
  "short-description":
    "Europe's premier auto technology R&D campus, located in central England, a short distance from Nuneaton town centre. The existing campus already includes over 30 major auto OEM brands, and has space to grow to 2m sqft. New Southern Manufacturing site will accommodate a further 2.5msqft of large scale manufacturing within 145 acres.",
  image: {
    url: Image1,
    alt: "Mira Technology Park",
    thumbnail: Image1Cropped,
  },
  position: {
    top: 10,
    left: 65,
  },
  ecosystem: [
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "Europe's fastest growing automotive tech campus.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image2,
        alt: "Facilities at the Mira Technology Park",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Test and development facilities on site for CAV and battery technology, including new £35m Assured CAV, in partnership with Coventry University, and part of the CAM Testbed UK Programme.",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image3,
        alt: "Test Track Facilities",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "2msqft of space for R&D and testing",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image4,
        alt: "Plug and Play",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content: "New allocation for B1 and B2 up to 145 acres",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image5,
        alt: "MIRA CGI",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "30 mins drive from East Midlands and Birmingham International Airports and HS2 Hub Station",
    },
    {
      type: IMAGE_CONTENT_TYPE,
      content: {
        url: Image6,
        alt: "MIRA CGI Aerial View",
      },
    },
    {
      type: PARAGRAPH_CONTENT_TYPE,
      content:
        "Over 40 OEMs onsite, including recent investments from REE Auto (Israel), Aimmo (Korea) and Polestar (Sweden/ China).",
    },
  ],
  stats: [
    {
      title: "Size",
      value: "2m sqft within 145 acres (59ha)",
    },
    {
      title: "Usage",
      value: "B1 and B1, B2",
    },
    {
      title: "Developer",
      value: "HORIBA MIRA",
    },
    {
      title: "Authority",
      value: "North Warwickshire Borough Council",
    },
    {
      title: "Local to",
      value: "Bosch, Toyota, Changan, Polestar, REE",
    },
  ],
  contact: {
    website: {
      url: "https://www.miratechnologypark.com/",
      title: "MIRA Technology Park and Southern Manufacturing Site",
    },
    postcode: "CV10 0TU",
    links: [
      {
        url: "https://www.youtube.com/watch?v=7btpyOh2TNc",
        title: "HORIBA MIRA Corporate Video",
      },
    ],
  },
};
