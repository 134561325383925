import React, { PureComponent } from "react";
import { ReactComponent as RotateDeviceIcon } from "../../assets/graphics/rotate-device.svg";
import "./rotate-device.scss";

class RotateDevicePresentational extends PureComponent {
  render() {
    return (
      <div className="rotate-device-container">
        <div className="rotate-device">
          <div className="rotate-device__icon">
            <RotateDeviceIcon />
          </div>
          <p className="rotate-device__message">
            Please rotate your device to view the map
          </p>
        </div>
      </div>
    );
  }
}

export default RotateDevicePresentational;
