import { combineReducers } from "redux";
import { mapStatus } from "./map-status/reducer";
import { contentStatus } from "./content-status/reducer";
import { imageOverlay } from "./image-overlay/reducer";

export const allReducers = combineReducers({
  contentStatus,
  mapStatus,
  imageOverlay,
});
