import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MessageOverlay from "../message-overlay";
import "./welcome-message.scss";

class WelcomeMessagePresentational extends PureComponent {
  render() {
    const { afterClosed } = this.props;
    return (
      <MessageOverlay buttonText={"Explore"} afterClosed={afterClosed} isOpen>
        <h1 className="welcome-message__header">Innovate Here</h1>
        <h2 className="welcome-message__subheader">Tech and R&D Sites</h2>
        <p className="welcome-message__intro">
          Explore our interactive map to find the right location in Coventry &
          Warwickshire for you or your client's business.
        </p>
      </MessageOverlay>
    );
  }
}

WelcomeMessagePresentational.propTypes = {
  afterClosed: PropTypes.func.isRequired,
};

export default WelcomeMessagePresentational;
