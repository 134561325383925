import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./map-road-label.scss";

class RoadLabelPresentational extends PureComponent {
  render() {
    const { type, position, label, rotation, scaleOffset } = this.props;
    const classes = classnames("road-label", {
      "road-label--major": type === "major",
    });
    const style = {
      ...position,
      transform:
        "translate(-50%, -50%) scale(" +
        scaleOffset +
        ") rotate(" +
        rotation +
        "deg)",
    };

    return (
      <div className={classes} style={style}>
        {label}
      </div>
    );
  }
}

RoadLabelPresentational.propTypes = {
  type: PropTypes.oneOf(["major", "minor"]),
  label: PropTypes.string,
  position: PropTypes.shape({
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
  }),
  rotation: PropTypes.number,
  scaleOffset: PropTypes.number,
};

RoadLabelPresentational.defaultProps = {
  type: "major",
  rotation: 0,
  scaleOffset: 1,
};

export default RoadLabelPresentational;
