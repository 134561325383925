import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setImageInOverlay } from "../../../../actions/image-overlay/actions";
import ImageContentBlockPresentational from "./presentational";
import { getImageDimensions } from "../../../../helpers/images";

class ImageContentBlockFunctional extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      showLoader: false,
    };

    this.loaderTimeout = null;

    this._zoomIn = this._zoomIn.bind(this);
    this._markImageAsLoaded = this._markImageAsLoaded.bind(this);
  }

  componentDidMount() {
    // Only show the loader is loading > 1/2 a second.
    this.loaderTimeout = setTimeout(() => {
      const { isLoading } = this.state;

      if (isLoading) {
        this.setState({
          showLoader: true,
        });
      }
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.loaderTimeout);
  }

  _zoomIn() {
    const { url, bigUrl, alt, openImageOverlay } = this.props;
    const urlToOpen = bigUrl !== undefined ? bigUrl : url;
    openImageOverlay(urlToOpen, alt);
  }

  _markImageAsLoaded() {
    this.setState({
      isLoading: false,
      showLoader: false,
    });
  }

  render() {
    const { url, alt, caption } = this.props;
    const { showLoader } = this.state;
    const sizes = getImageDimensions(url);

    return (
      <ImageContentBlockPresentational
        url={url}
        alt={alt}
        caption={caption}
        dimensions={sizes}
        onZoom={this._zoomIn}
        isLoading={showLoader}
        onLoad={this._markImageAsLoaded}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openImageOverlay: (url, alt) => {
      dispatch(setImageInOverlay({ url, alt }));
    },
  };
};

ImageContentBlockFunctional.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
  bigUrl: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(ImageContentBlockFunctional);
